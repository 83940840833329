import { useState } from "react";
import beerMp3 from "../../../assets/beer.mp3";
import { HOST_STATUS } from "../../../common/client/host/HostClient";
import { PARTY_MAX_PLAYER_SLOTS } from "../../../common/client/host/constants";
import PlayerClient from "../../../common/client/player/PlayerClient";
import Button from "../../../common/ui/button/Button";
import { H5 } from "../../../common/ui/heading/Heading";
import { usePlayerStore } from "../usePlayerStore";
import classes from "./PlayerMenu.module.css";

type Props = {
  onClose: () => void;
};

const PlayersListMenu = () => {
  const players = usePlayerStore((state) => state.players);
  const isPartyLeader = usePlayerStore((state) => state.isPartyLeader);

  return (
    <div className="p-2">
      <H5>{`Players - ${players.length}/${PARTY_MAX_PLAYER_SLOTS}`}</H5>
      <div className={classes.players}>
        {players.map((player) => (
          <div key={player.getPlayerId()} className={classes.player}>
            <div>{player.getName()}</div>
            <Button
              onClick={() => {
                PlayerClient.kickPlayer(player.getPlayerId());
              }}
              disabled={!isPartyLeader || player.getPlayerId() === PlayerClient.playersManager.getSelf()?.getPlayerId()}
            >
              Kick
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

const PlayerMenu = ({ onClose }: Props) => {
  const players = usePlayerStore((state) => state.players);
  const isPartyLeader = usePlayerStore((state) => state.isPartyLeader);
  const hostState = usePlayerStore((state) => state.hostState);
  const [playersMenuOpen, setPlayersMenuOpen] = useState(false);

  const [audio] = useState(new Audio(beerMp3));

  const menuButtons = [
    {
      label: "Exit party",
      onClick: () => {
        PlayerClient.disconnect();
      },
    },
  ];

  if (hostState?.status === HOST_STATUS.PLAYING) {
    menuButtons.push({
      label: "Toggle pause",
      onClick: () => {
        PlayerClient.requestTogglePause();
        onClose();
      },
    });
  }

  if (isPartyLeader) {
    if (hostState?.status === HOST_STATUS.PLAYING) {
      menuButtons.push({
        label: "Skip round",
        onClick: () => {
          PlayerClient.requestSkipRound();
          onClose();
        },
      });
      menuButtons.push({
        label: "Exit to lobby",
        onClick: () => {
          PlayerClient.requestExitToLobby();
          onClose();
        },
      });
    }
  } else {
    menuButtons.push({
      label: "Take Party Lead",
      onClick: () => {
        PlayerClient.requestPartyLeaderSwitch();
      },
    });
    menuButtons.push({
      label: "Open beer",
      onClick: () => {
        audio.currentTime = 0;
        void audio.play();
      },
    });
  }

  return (
    <div className={classes.container}>
      {playersMenuOpen ? (
        <div className="flex-1">
          <PlayersListMenu />
        </div>
      ) : (
        <>
          <div className="flex h-20 p-5">
            <div className="flex-1">
              {hostState?.status === HOST_STATUS.PLAYING && (
                <Button
                  onClick={() => {
                    setPlayersMenuOpen(!playersMenuOpen);
                  }}
                >
                  {`Players - ${players.length}/${PARTY_MAX_PLAYER_SLOTS}`}
                </Button>
              )}
            </div>
          </div>
          <div style={{ flex: 1 }}>{hostState?.status === HOST_STATUS.LOBBY && <PlayersListMenu />}</div>

          <div className={classes.menuGrid}>
            {menuButtons.map((button) => (
              <div key={button.label} className={classes.menuGridItem}>
                <Button onClick={button.onClick}>{button.label}</Button>
              </div>
            ))}
          </div>
        </>
      )}
      <div className={classes.actions}>
        <Button
          onClick={() => {
            if (playersMenuOpen) {
              setPlayersMenuOpen(false);
              return;
            }
            onClose();
          }}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default PlayerMenu;
