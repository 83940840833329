import { PlayerClientConnectionStats } from "./../../common/client/player/PlayerClientConnection";
import { createStore } from "zustand";
import { combine } from "zustand/middleware";
import GameEntry from "../../common/client/GameEntry";
import { PeerServerConnectionStatus } from "../../common/client/PeerManager";
import Player from "../../common/client/Player";
import { SharedHostState } from "../../common/client/host/HostClient";
import PlayerClient from "../../common/client/player/PlayerClient";
import { DataPoint } from "../../common/client/plugins/latency/types";
import { ClientConnectionStatus } from "../../common/client/ClientConnection";
import { CircularArray } from "../../common/dataStructures/CircularArray";
import clientLocalStorage from "../storage/clientLocalStorage";

export interface PlayerProps {
  inParty: boolean;
  players: Player[];
  player: Player | null;
  isPartyLeader: boolean;
  hostState: SharedHostState | null;
  gameEntry: GameEntry | null;
  peerServerConnectionStatus: PeerServerConnectionStatus | null;
  clientConnectionStatus: ClientConnectionStatus | null;
  networkLatency: {
    latency: number;
    dataPoints: CircularArray<DataPoint> | null;
  };
  playerClientConnectionStats: PlayerClientConnectionStats | null;
  isConsoleLogOpen: boolean;
}

export interface PlayerState extends PlayerProps {
  actions: {
    connect: (partyId: string, playerName: string) => void;
    setConsoleLogOpen: (isOpen: boolean) => void;
    disconnect: () => void;
  };
}

export type PlayerStore = ReturnType<typeof createPlayerStore>;

const createPlayerStore = () => {
  const DEFAULT_PROPS: PlayerProps = {
    inParty: false,
    isPartyLeader: false,
    players: [],
    hostState: null,
    gameEntry: null,
    player: null,
    peerServerConnectionStatus: null,
    clientConnectionStatus: null,
    networkLatency: {
      latency: -1,
      dataPoints: null,
    },
    playerClientConnectionStats: null,
    isConsoleLogOpen: false,
  };

  return createStore<PlayerState>(
    combine(DEFAULT_PROPS, (setState) => {
      const urlParams = new URLSearchParams(window.location.search);
      const isForceRelay = urlParams.get("relay") !== null;

      PlayerClient.setup({
        forceRelay: isForceRelay,
      });

      window.playerClient = PlayerClient;

      PlayerClient.peerManager.onPeerServerConnectionStatusChange(({ status }) => {
        setState({
          peerServerConnectionStatus: status,
        });
      });

      PlayerClient.peerManager.on("peer_connection_stats", (data) => {
        setState({
          playerClientConnectionStats: data,
        });
      });

      PlayerClient.latencyPlugin.on("update", (data) => {
        setState({
          networkLatency: {
            latency: data.dataPoint.latency,
            dataPoints: data.dataPoints,
          },
        });
      });

      PlayerClient.on("host_state_change", (hostState) => {
        setState({
          hostState,
        });
      });

      PlayerClient.on("joined_party", (data) => {
        setState({
          inParty: true,
          isPartyLeader: PlayerClient.party.getIsSelfPartyLeader(),
          players: PlayerClient.party.getPlayers(),
          player: PlayerClient.playersManager.getSelf()?.clone(),
          hostState: data.hostState,
        });
      });

      PlayerClient.on("party_change", () => {
        setState({
          isPartyLeader: PlayerClient.party.getIsSelfPartyLeader(),
          players: PlayerClient.party.getPlayers(),
          player: PlayerClient.playersManager.getSelf()?.clone(),
        });
      });

      PlayerClient.on("left_party", () => {
        setState({ inParty: false });
      });

      PlayerClient.gameManager.on("setup_game", (data) => {
        setState({
          gameEntry: data.gameEntry,
        });
      });

      PlayerClient.gameManager.on("unload_game", () => {
        setState({
          gameEntry: null,
        });
      });

      PlayerClient.peerManager.on("connection_status_change", ({ status }) => {
        setState({
          clientConnectionStatus: status,
        });
      });

      PlayerClient.on("request_party_leader_switch", ({ playerId }) => {
        PlayerClient.acceptPartyLeaderSwitch(playerId);
      });

      return {
        actions: {
          connect(partyId: string, playerName: string) {
            clientLocalStorage.setItem("partyId", partyId);
            clientLocalStorage.setItem("playerName", playerName);
            PlayerClient.connect(partyId, playerName);
          },
          disconnect() {
            PlayerClient.disconnect();
          },
          setConsoleLogOpen(isOpen: boolean) {
            setState({ isConsoleLogOpen: isOpen });
          },
        },
      };
    }),
  );
};

let store: PlayerStore | null;
export default () => {
  if (!store) {
    store = createPlayerStore();
  }
  return store;
};
