import "@total-typescript/ts-reset";
import React from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import "./domain/sentry/sentry";
import "./index.css";

import FullScreenProvider from "./common/fullScreen/FullScreenProvider";
import ForceRootRouteEntry from "./common/router/ForceRootRouteEntry";
import { IS_DEV } from "./domain/constants";
import { HOST_ROOT_PATH } from "./domain/host/constants";
import GameAnalyticsScript from "./GameAnalyticsScript";
import HostClient from "./domain/host/HostClient";
import { PLAYER_ROOT_PATH } from "./domain/player/constants";
import PlayerClient from "./domain/player/PlayerClient";

const router = createHashRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: `${HOST_ROOT_PATH}/*`,
    element: (
      <ForceRootRouteEntry enabled={!IS_DEV} rootPath={HOST_ROOT_PATH}>
        {() => <HostClient />}
      </ForceRootRouteEntry>
    ),
  },
  {
    path: "/player",
    element: (
      <ForceRootRouteEntry enabled={!IS_DEV} rootPath={PLAYER_ROOT_PATH}>
        {() => <PlayerClient />}
      </ForceRootRouteEntry>
    ),
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <GameAnalyticsScript />
    <FullScreenProvider>
      <RouterProvider router={router} />
    </FullScreenProvider>
  </React.StrictMode>,
);
