import { GameEntryData } from "../../../../common/client/GameEntry";
import GameEntry from "../../../../common/client/GameEntry";
import useFocusableWithAction from "../../spatialNavigation/useFocusableWithAction";
import TvNoiseEffectOverlay from "../../animation/TvNoiseEffectOverlay";
import DetailsBox from "../../playlist/DetailsBox";
import { useSwiper } from "swiper/react";
import { useEffect } from "react";
import { setFocus } from "@noriginmedia/norigin-spatial-navigation";
import { playSound, SOUNDS } from "../../../sounds/ui/sounds";

interface Props {
  focusKey: string;
  className?: string;
  forceFocus?: boolean;
  gameEntryData: GameEntryData;
  onSelect: () => void;
  swiperIndex: number;
  onActionDirection: (direction: string) => void;
}

const GameItem = ({
  focusKey,
  forceFocus,
  className,
  gameEntryData,
  onSelect,
  swiperIndex,
  onActionDirection,
}: Props) => {
  const swiper = useSwiper();
  const gameEntry = new GameEntry(gameEntryData);
  const { ref, focused } = useFocusableWithAction({
    focusKey,
    forceFocus: !!forceFocus,
    onActionEnter: () => {
      playSound(SOUNDS.BUTTON_PRESS, { throttleMs: 50 });
      playSound(SOUNDS.NEXT_ITEM, { throttleMs: 50 });
      onSelect && onSelect();
    },
    onFocus: () => {
      playSound(SOUNDS.BUTTON_PRESS, { throttleMs: 50 });
    },
    onActionDirection: (direction) => {
      onActionDirection(direction);
      return false;
    },
  });

  useEffect(() => {
    setFocus("");
  }, []);

  useEffect(() => {
    if (focused) {
      swiper.slideTo(swiperIndex);
    }
  }, [focused, swiper, swiperIndex]);

  return (
    <div
      ref={ref}
      tabIndex={0}
      className={[className, "relative flex h-[426px] w-1/4 px-[10px] outline-0"].filter(Boolean).join(" ")}
    >
      <div className="relative flex w-full shadow-[5px_5px_2px] shadow-indigo-950/75">
        <div className="flex w-full flex-col bg-indigo-950">
          <div className="flex h-[86px] w-full items-center bg-violet-700 px-[15px] text-[30px] font-bold">
            {gameEntry.getGameName()}
          </div>
          <div className="flex h-[60px] w-full items-center bg-violet-700/50 px-[15px] text-[27px] font-medium">
            {gameEntry.getGameModeName()}
          </div>
          <div
            className="relative flex-1 overflow-hidden bg-cover bg-center"
            style={{ backgroundImage: `url(${gameEntry.getCoverImage()})` }}
          >
            {focused && <TvNoiseEffectOverlay />}
            <DetailsBox
              className="absolute bottom-[21px] right-[20px] bg-indigo-950 bg-opacity-40"
              isChild={false}
              label="Players"
              value={`${gameEntry.getMinPlayers()}-${gameEntry.getMaxPlayers()}`}
            />
          </div>
        </div>
        <div
          className={`absolute -bottom-[2px] -left-[2px] -right-[2px] -top-[2px] border-solid transition-all ease-in-out ${focused ? "border-[4px] border-fuchsia-500 shadow-[0_0_10px_2px] shadow-fuchsia-500" : "border-transparent"}`}
        />
      </div>
    </div>
  );
};

export default GameItem;
