import { PLAYER_COLORS } from "@shared/src/gc/constants";
import { FrameEventData, FrameEventMessage } from "@shared/src/gc/events/FrameEventEmitter";
import { GameEventMessage } from "@shared/src/gc/events/GameEventEmitter";
import { HostClientEventData, HostClientEventName } from "@shared/src/gc/events/HostClientEventEmitter";
import PlayerClientEventEmitter from "@shared/src/gc/events/PlayerClientEventEmitter";
import { EVENT_TYPE } from "@shared/src/gc/events/constants";
import { SharedPartyState } from "@shared/src/gc/types";
import { NavigationButton } from "../../../domain/host/spatialNavigation/types";
import clientLocalStorage from "../../../domain/storage/clientLocalStorage";
import { assert } from "../../logger/assert";
import { splitStringIntoParts } from "../../utils/string";
import BaseClient, { BaseClientEvents, UnknownFrameMessage } from "../BaseClient";
import { PEER_SERVER_CONNECTION_STATUS } from "../PeerManager";
import Player from "../Player";
import { CLIENT_CONNECTION_STATUS, HOST_PEER_ID_PREFIX } from "../constants";
import HudHostPlugin from "../plugins/hud/HudHostPlugin";
import LatencyHostPlugin from "../plugins/latency/LatencyHostPlugin";
import PlaylistBlueprint from "../plugins/playlist/PlaylistBlueprint";
import PlaylistHostPlugin, {
  PLAYLIST_STATES,
  PlaylistSharedClientState,
  PlaylistState,
  StateInterfaceForState,
} from "../plugins/playlist/PlaylistHostPlugin";
import { PlaylistData } from "../plugins/playlist/types";
import HostGameManager from "./HostGameManager";
import HostParty from "./HostParty";
import HostPeerManager, { PLAYER_CONNECT_TYPE } from "./HostPeerManager";
import HostPlayersManager from "./HostPlayersManager";
import { GAME_UNPAUSE_DELAY_SECONDS, MAX_PLAYER_NAME_LENGTH } from "./constants";

export type SharedHostState = {
  status: HostStatus;
  playlist: PlaylistSharedClientState | null;
};

export const HOST_STATUS = {
  CONNECTING_TO_PEER_SERVER: "connecting_peer_server",
  CONNECTING_TO_PEER_SERVER_FAILED: "connecting_peer_server_failed",
  LOBBY: "lobby",
  PLAYING: "playing",
} as const;

type HostStatus = (typeof HOST_STATUS)[keyof typeof HOST_STATUS];

export type HostClientEventMessage = {
  type: "HOST_CLIENT_EVENT";
  eventName: HostClientEventName;
  data?: HostClientEventData<HostClientEventName>;
};

type Events = BaseClientEvents & {
  host_state_change: (hostState: SharedHostState) => void;
  party_player_joined: (data: { playerId: number }) => void;
  player_reconnected: (data: { playerId: number }) => void;
  party_change: (data: SharedPartyState) => void;
  party_player_left: (data: { playerId: number }) => void;
  setup_game_done: (data: { playerId: number }) => void;
  party_leader_input: (data: { button: NavigationButton }) => void;
};

export class HostClient extends BaseClient<Events> {
  readonly playersManager: HostPlayersManager;
  readonly party: HostParty;
  readonly gameManager: HostGameManager;
  readonly peerManager: HostPeerManager;
  readonly playerClientEvents: PlayerClientEventEmitter;
  private hostStatus: HostStatus = HOST_STATUS.CONNECTING_TO_PEER_SERVER;
  private playlist: PlaylistHostPlugin | null = null;
  readonly hud: HudHostPlugin;

  constructor() {
    super(true);
    this.peerManager = new HostPeerManager();
    this.playersManager = new HostPlayersManager();
    this.party = new HostParty(this);
    this.gameManager = new HostGameManager();
    this.playerClientEvents = new PlayerClientEventEmitter();
    this.hud = new HudHostPlugin();
  }

  public setup(options?: { forceRelay?: boolean }): void {
    super.setup();

    this.gameManager.setup(this);
    this.peerManager.setup({
      forceRelay: options?.forceRelay,
    });

    this.hud.setup(this);
    this.addPlugin(this.hud);

    const latencyPlugin = new LatencyHostPlugin();
    latencyPlugin.setup(this);
    this.addPlugin(latencyPlugin);

    // Share host peer id via localStorage for testing purposes
    import.meta.env.DEV && clientLocalStorage.setItem("devAutofillPartyId", this.peerManager.getPeer().id);

    this.party.setup();

    this.setupPeerManagerEvents();
    this.setupPlayersManagerEvents();
    this.setupPartyEvents();
    this.setupPlayerClientEvents();
    this.setupFrameEvents();
  }

  private getPlayerConnectState(player: Player) {
    return {
      playerId: player.getPlayerId(),
      hostState: this.getSharedState(),
      playersState: this.playersManager.getSharedClientState(),
      partyState: this.party.getSharedState(),
      gameEntry: this.gameManager.getGameEntry()?.getData(),
    };
  }

  private setupPeerManagerEvents() {
    this.peerManager.onPeerServerConnectionStatusChange((data) => {
      switch (data.status) {
        case PEER_SERVER_CONNECTION_STATUS.CONNECTED:
          if (this.hostStatus === HOST_STATUS.CONNECTING_TO_PEER_SERVER) {
            this.setHostStatus(HOST_STATUS.LOBBY);
          }
          break;
        case PEER_SERVER_CONNECTION_STATUS.DISCONNECTED:
          this.setHostStatus(HOST_STATUS.CONNECTING_TO_PEER_SERVER_FAILED);
          break;
      }
    });

    this.peerManager.onConnected((peerId, playerConnectMetadata) => {
      assert(playerConnectMetadata?.type, "Invalid connect data. Missing type");
      assert(
        Object.values(PLAYER_CONNECT_TYPE).includes(playerConnectMetadata.type),
        "Invalid connect data. Invalid type",
      );
      assert(playerConnectMetadata?.playerName, "Invalid connect data. Missing playerName");

      const isSilentAutoReconnect = playerConnectMetadata.type === PLAYER_CONNECT_TYPE.SILENT_AUTO_RECONNECT;
      const playerName = playerConnectMetadata.playerName;

      if (!playerName) {
        this.peerManager.sendMessage(peerId, {
          type: "error",
          message: "Player name required",
        });
        this.peerManager.closePeerById(peerId);
        return;
      }

      if (playerName.length > MAX_PLAYER_NAME_LENGTH) {
        this.peerManager.sendMessage(peerId, {
          type: "error",
          message: "Player name too long (max 8 characters)",
        });
        this.peerManager.closePeerById(peerId);
        return;
      }

      const existingPlayer = this.playersManager.getPlayerByName(playerName);

      if (existingPlayer) {
        const connectionStatus = this.peerManager.getConnectionStatus(existingPlayer.getPeerId());

        if (
          !isSilentAutoReconnect &&
          existingPlayer.getIsActive() &&
          connectionStatus === CLIENT_CONNECTION_STATUS.CONNECTED_ACTIVE
        ) {
          this.peerManager.sendMessage(peerId, {
            type: "error",
            message: "Player with name already in party",
          });
          this.peerManager.closePeerById(peerId);
          return;
        }
      }

      const player = existingPlayer || this.playersManager.createPlayer(playerName, peerId);

      // player already in party, so just reconnect by sending the state
      if (this.party.getPlayerByPlayerId(player.getPlayerId())) {
        const playerPeerId = player.getPeerId();
        if (playerPeerId !== peerId) {
          player.setPeerId(peerId);
          this.peerManager.closePeerById(playerPeerId);
        }

        this.sendEventToPlayerClient(player.getPlayerId(), "joined_party", this.getPlayerConnectState(player));
        this.dispatch("player_reconnected", {
          playerId: player.getPlayerId(),
        });
        return;
      }

      const slotNumber = this.party.tryGetFreeSlotNumber();

      switch (slotNumber) {
        case "party_full":
          this.peerManager.sendMessage(peerId, {
            type: "error",
            message: "Party is full",
          });
          this.peerManager.closePeerById(peerId);
          break;
        default:
          if (existingPlayer) {
            existingPlayer.setPeerId(peerId);
            this.playersManager.setPlayerActive(existingPlayer);
          } else {
            this.playersManager.addPlayer(player);
          }
          player.setColor(PLAYER_COLORS[slotNumber]);
          this.sendEventToPlayerClient(player.getPlayerId(), "joined_party", this.getPlayerConnectState(player));
          this.party.addPlayer(player);
          break;
      }
    });

    this.peerManager.onGameEvent((event) => {
      // This is game specific event, do not react to these here!
      this.postMessageToFrame(event);
    });
    this.peerManager.onPlayerClientEvent((event) => {
      this.playerClientEvents.dispatch(event.playerId, event.eventName, event.data);

      this.plugins.forEach((plugin) => {
        plugin.handlePlayerClientEvent(event);
      });
    });
    this.peerManager.onClose((peerId: string) => {
      const player = this.playersManager.getPlayerByPeerId(peerId);
      if (player) {
        player.setPeerId("");
        this.party.removePlayer(player.getPlayerId());
        this.playersManager.setPlayerInactive(player);
      }
    });
    this.peerManager.onPeerError((error) => {
      this.dispatch("peer_error", error);
    });
    this.peerManager.onConnectionError((_peerId, error) => {
      this.dispatch("connection_error", error);
    });
  }

  private setupPlayersManagerEvents() {
    this.playersManager.on("change", (data) => {
      this.sendEventToAllPlayerClients("players_change", data);
    });
  }

  private setupPartyEvents() {
    this.party.on("change", (partyState) => {
      this.sendEventToAllPlayerClients("party_change", partyState);
      this.postEventToFrame("party_change", partyState, false);
      this.dispatch("party_change", partyState);
    });
    this.party.on("player_joined", (data) => {
      this.dispatch("party_player_joined", data);
    });
    this.party.on("player_left", (data) => {
      this.sendEventToAllPlayerClients("party_player_left", data);
      this.postEventToFrame("party_player_left", data, false);
      this.dispatch("party_player_left", data);
    });
  }

  private setupPlayerClientEvents() {
    this.playerClientEvents.on("setup_game_done", (playerId) => {
      this.dispatch("setup_game_done", { playerId });
    });
    this.playerClientEvents.on("party_leader_input", (_playerId, data) => {
      this.dispatch("party_leader_input", data);
    });
    this.playerClientEvents.on("request_skip_round", () => {
      this.skipRound();
    });
    this.playerClientEvents.on("request_toggle_pause", () => {
      this.togglePause();
    });
    this.playerClientEvents.on("request_pause", (_playerId, pause) => {
      this.pause(pause);
    });
    this.playerClientEvents.on("request_exit_to_lobby", () => {
      this.endPlaylist();
    });
    this.playerClientEvents.on("request_party_leader_switch", (playerId) => {
      this.sendEventToPlayerClient(playerId, "request_party_leader_switch", {
        playerId,
      });
    });
    this.playerClientEvents.on("accept_party_leader_switch", (_, data) => {
      this.party.setPartyLeader(data.playerId);
    });
    this.playerClientEvents.on("kick_player", (playerId, data) => {
      if (playerId !== this.party.getPartyLeader()?.getPlayerId()) {
        throw new Error("Only party leader can kick players");
      }
      this.kickPlayer(data.playerId);
    });
  }

  private setupFrameEvents() {
    this.frameEvents.on("frame_script_ready", () => {
      assert(this.party, "expected party");

      const gameEntry = this.gameManager.getGameEntry();
      assert(gameEntry, "expected gameEntry");

      this.postEventToFrame("frame_init", {
        gameId: gameEntry.getGameId(),
        gameModeId: gameEntry.getGameModeId(),
        partyState: this.party.getSharedState(),
      });
    });
  }

  protected handleMessageFromFrame = (message: UnknownFrameMessage) => {
    switch ((message.data as { type?: string }).type) {
      case EVENT_TYPE.GAME_EVENT:
        {
          // This is game specific event, do not react to these here!
          const gameEvent = message.data as GameEventMessage;
          this.peerManager.sendMessageToAllPeers(gameEvent, gameEvent.reliable);
        }
        break;
      case EVENT_TYPE.FRAME_EVENT: {
        const frameEvent = message.data as FrameEventMessage;
        this.frameEvents.dispatch(frameEvent.eventName, frameEvent.data as FrameEventData);

        this.plugins.forEach((plugin) => {
          plugin.handleFrameEvent(frameEvent);
        });
        break;
      }
    }
  };

  private setHostStatus(status: HostStatus) {
    if (status === this.hostStatus) {
      throw new Error(`Host status is already "${status}"`);
    }
    this.hostStatus = status;
    this.dispatchHostStateChange();
  }

  private dispatchHostStateChange = (): void => {
    const hostState = this.getSharedState();
    this.sendEventToAllPlayerClients("host_state_change", { hostState });
    this.dispatch("host_state_change", hostState);
  };

  /**
   * Shared state is shared with all player clients
   */
  getSharedState(): SharedHostState {
    return {
      status: this.hostStatus,
      playlist: this.playlist ? this.playlist.getSharedClientState() : null,
    };
  }

  sendMessage(playerId: number, data: object): void {
    const peerId = this.playersManager.getPeerIdByPlayerId(playerId);
    if (!peerId) {
      throw new Error(`peerId not found by playerId ${playerId}`);
    }
    this.peerManager.sendMessage(peerId, data);
  }

  createHostClientEventMessage(
    eventName: HostClientEventName,
    data?: HostClientEventData<HostClientEventName>,
  ): HostClientEventMessage {
    return { type: EVENT_TYPE.HOST_CLIENT_EVENT, eventName, data };
  }

  sendEventToPlayerClient(
    playerId: number,
    eventName: HostClientEventName,
    data?: HostClientEventData<HostClientEventName>,
    reliable = true,
  ): void {
    const peerId = this.playersManager.getPeerIdByPlayerId(playerId);
    if (!peerId) {
      throw new Error(`peerId not found by playerId ${playerId}`);
    }

    const message = this.createHostClientEventMessage(eventName, data);
    this.peerManager.sendMessage(peerId, message, reliable);
  }

  sendEventToAllPlayerClients(eventName: HostClientEventName, data?: HostClientEventData<HostClientEventName>) {
    const message = this.createHostClientEventMessage(eventName, data);
    this.peerManager.sendMessageToAllPeers(message);
  }

  startPlaylistFromBlueprint(playlistBlueprint: PlaylistBlueprint) {
    this.startPlaylist(PlaylistHostPlugin.createPlaylistDataFromBlueprint(playlistBlueprint));
  }

  startPlaylist(playlistData: PlaylistData) {
    assert(this.hostStatus === HOST_STATUS.LOBBY, "Can't start playlist when not in lobby");

    if (this.party.getPlayers().length < playlistData.minPlayers) {
      this.dispatch("error", {
        message: "Need at least 2 players to start a playlist",
      });
      return;
    }

    if (this.party.getPlayers().length > playlistData.maxPlayers) {
      this.dispatch("error", {
        message: "Too many players to start a playlist. Max players: " + playlistData.maxPlayers,
      });
      return;
    }

    const playlist = new PlaylistHostPlugin();
    this.addPlugin(playlist);
    playlist.setup(this);

    playlist.on("state_change", (props) => {
      switch (props.newState) {
        case PLAYLIST_STATES.GAME_END:
        case PLAYLIST_STATES.PRE_PLAY:
        case PLAYLIST_STATES.PLAYLIST_END:
          this.hud.clear();
          break;
      }
      this.dispatchHostStateChange();
    });

    let unpauseTimeout: ReturnType<typeof setTimeout> | null = null;

    playlist.on("pause", (isPaused) => {
      if (!isPaused) {
        // delay the pause for the game, so players has time to adjust
        unpauseTimeout = setTimeout(() => {
          this.postEventToFrame("pause", false);
        }, GAME_UNPAUSE_DELAY_SECONDS * 1000);
      } else {
        if (unpauseTimeout) {
          clearTimeout(unpauseTimeout);
          unpauseTimeout = null;
        }

        this.postEventToFrame("pause", true);
      }

      this.dispatchHostStateChange();
    });

    playlist.on("exit", () => {
      this.playlist = null;
      this.removePlugin(playlist);
      this.setHostStatus(HOST_STATUS.LOBBY);
    });

    playlist.start(playlistData);

    this.playlist = playlist;

    this.setHostStatus(HOST_STATUS.PLAYING);
  }

  skipRound() {
    assert(this.playlist, "No playlist to skip round");
    this.playlist.skipRound();
  }

  pause(pause: boolean) {
    assert(this.playlist, "No playlist to pause");
    this.playlist.pause(pause);
  }

  togglePause() {
    assert(this.playlist, "No playlist to pause");
    this.playlist.togglePause();
  }

  getPlaylistStateInterface<T extends PlaylistState>(stateId: T): StateInterfaceForState<T> {
    assert(this.playlist, "No playlist to proceed");
    return this.playlist.getStateInterfaceAs(stateId);
  }

  endPlaylist() {
    assert(this.playlist, "No playlist to end");
    this.playlist.end();
  }

  kickPlayer(playerId: number) {
    const player = this.party.getPlayerByPlayerId(playerId);
    assert(player, "Player not found");
    this.peerManager.closePeerById(player.getPeerId());
  }

  formatPartyId(partyId: string) {
    partyId = partyId.replace(HOST_PEER_ID_PREFIX, "");
    partyId = partyId.replace(/[^0-9]/g, "");
    partyId = partyId.replace(/ /g, "");
    partyId = partyId.substring(0, 6);

    return splitStringIntoParts(partyId, 3).join(" ");
  }

  getPartyId(formatted: boolean = false) {
    const peerId = this.peerManager.getPeerId().replace(HOST_PEER_ID_PREFIX, "");
    return formatted ? this.formatPartyId(peerId) : peerId;
  }

  getPlayerConnectionStatus(playerId: number) {
    const player = this.playersManager.getPlayerByPlayerId(playerId);
    assert(player, "expected player");
    const connectionStatus = this.peerManager.getConnectionStatus(player.getPeerId());
    assert(connectionStatus, "expected connectionStatus");
    return connectionStatus;
  }
}

export type HostClientType = HostClient;

export default new HostClient();
