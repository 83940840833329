import { Link } from "react-router-dom";
import HostClient from "../../../../common/client/host/HostClient";

import { useTimedState } from "../../../../common/hook/useTimedState";
import HeaderPlayers from "./players/HeaderPlayers";

const LobbyHeader = () => {
  const partyId = HostClient.getPartyId(true);

  const [copiedConfirmation, setCopiedConfirmation] = useTimedState(false, 3000);

  return (
    <div className="relative flex max-h-[204px] min-h-[204px] w-full items-center justify-start bg-indigo-950">
      <div className="w-[242px] px-10">
        <Link to="/host">
          <img className="pointer-events-none h-[105px] select-none" src="/gc-logo.svg" alt="" />
        </Link>
      </div>
      <div className="h-full flex-1 items-center justify-center px-5">
        <HeaderPlayers />
      </div>
      {copiedConfirmation && (
        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-black bg-opacity-50 duration-3000 ease-in animate-out fade-out-0">
          <div className="text-2xl font-bold text-white">Party ID copied to clipboard</div>
        </div>
      )}
      <div
        className="flex w-[242px] flex-col justify-center px-6 text-center font-bold"
        onClick={() => {
          void navigator.clipboard.writeText(partyId).then(() => {
            setCopiedConfirmation(true);
          });
        }}
      >
        <span className="block text-2xl">Party ID</span>
        <span className="block text-4xl">{partyId}</span>
      </div>
    </div>
  );
};

export default LobbyHeader;
