import EventEmitter, { DefaultListener } from "@shared/src/gc/events/EventEmitter";
import { Peer, SocketEventType } from "peerjs";
import { assert } from "../logger/assert";

export const PEER_SERVER_CONNECTION_STATUS = {
  NONE: "none",
  CONNECTING: "connecting",
  CONNECTED: "connected",
  RECONNECTING: "reconnecting",
  RECONNECTING_FAILED: "reconnecting_failed",
  DISCONNECTED: "disconnected",
  CLOSED: "closed",
} as const;

export type PeerServerConnectionStatus =
  (typeof PEER_SERVER_CONNECTION_STATUS)[keyof typeof PEER_SERVER_CONNECTION_STATUS];

export interface PeerManagerEvents extends DefaultListener {
  peer_created: (peer: Peer) => void;
  peer_server_connection_status_change: (data: { status: PeerServerConnectionStatus }) => void;
  peer_error: (error: unknown) => void;
}

class PeerManager<T extends PeerManagerEvents> extends EventEmitter<T> {
  protected peer: Peer | null = null;
  private peerId: string | null = null;
  private peerServerConnectionStatus: PeerServerConnectionStatus = PEER_SERVER_CONNECTION_STATUS.NONE;
  private reconnectTimeoutHandle: ReturnType<typeof setTimeout> | null = null;
  private reconnectAttempts = 0;

  constructor() {
    super();
  }

  protected debug(...args: unknown[]) {
    console.debug(new Date().toLocaleTimeString(), "PeerManager -", ...args);
  }

  public setup() {
    this.debug("setup()");
  }

  private setPeerServerConnectionStatus(status: PeerServerConnectionStatus) {
    this.debug(`setPeerServerConnectionStatus() - status: ${this.peerServerConnectionStatus} -> ${status}`);

    this.peerServerConnectionStatus = status;
    this.dispatch("peer_server_connection_status_change", { status });
  }

  private tryReconnect() {
    this.debug("Reconnecting to peer server...");

    if (this.peerServerConnectionStatus !== PEER_SERVER_CONNECTION_STATUS.RECONNECTING) {
      this.setPeerServerConnectionStatus(PEER_SERVER_CONNECTION_STATUS.RECONNECTING);
    }

    let timeout;

    if (this.reconnectAttempts === 0) {
      timeout = 0;
    } else if (this.reconnectAttempts < 10) {
      timeout = 200;
    } else if (this.reconnectAttempts < 20) {
      timeout = 1000;
    } else if (this.reconnectAttempts < 30) {
      timeout = 10000;
    } else if (this.reconnectAttempts < 40) {
      timeout = 60000;
    } else if (this.reconnectAttempts < 60) {
      this.giveUpReconnecting();
      return;
    }

    this.reconnectTimeoutHandle = setTimeout(() => {
      console.debug(`peer.reconnect() called - attempt: ${this.reconnectAttempts}`);
      assert(this.peer, "peer expected");
      this.peer.reconnect();
    }, timeout);

    this.reconnectAttempts++;
  }

  private giveUpReconnecting() {
    this.debug("Giving up reconnecting!");

    // TODO: This would require manual reconnect attempt from the user...
    this.setPeerServerConnectionStatus(PEER_SERVER_CONNECTION_STATUS.RECONNECTING_FAILED);
  }

  createNewPeer(peerId: string, options?: { forceRelay?: boolean }) {
    this.debug("Create peer with ID: " + peerId + " Force relay: " + options?.forceRelay);

    if (this.reconnectTimeoutHandle) {
      clearTimeout(this.reconnectTimeoutHandle);
    }

    const getTurnServer = () => {
      if (
        import.meta.env.VITE_PEER_SERVER_TURN_URL !== "" &&
        import.meta.env.VITE_PEER_SERVER_TURN_USERNAME !== "" &&
        import.meta.env.VITE_PEER_SERVER_TURN_PASSWORD !== ""
      ) {
        return {
          url: import.meta.env.VITE_PEER_SERVER_TURN_URL,
          username: import.meta.env.VITE_PEER_SERVER_TURN_USERNAME,
          credential: import.meta.env.VITE_PEER_SERVER_TURN_PASSWORD,
        };
      }

      return null;
    };

    const peerOptions = {
      host: import.meta.env.VITE_PEER_SERVER_HOST,
      port: import.meta.env.VITE_PEER_SERVER_PORT,
      path: import.meta.env.VITE_PEER_SERVER_PATH,
      secure: import.meta.env.VITE_PEER_SERVER_SECURE === "true",
      debug: Number(import.meta.env.VITE_PEERJS_DEBUG),
      config: {
        iceServers: [{ url: import.meta.env.VITE_PEER_SERVER_STUN_URL }, getTurnServer()].filter(Boolean),
      } as Record<string, unknown>,
      pingInterval: 1000,
    };

    if (options?.forceRelay) {
      peerOptions.config.iceTransportPolicy = "relay";
    }

    // Register with the peer server (signaling server)
    const peer = new Peer(peerId, peerOptions);
    this.peerId = peerId; // store peerId as PeerJS might hide it during network error, but we still want to show it in the UI etc.

    this.setPeerServerConnectionStatus(PEER_SERVER_CONNECTION_STATUS.CONNECTING);

    peer.on("open", () => {
      this.debug("peer open");

      this.reconnectAttempts = 0;
      this.setPeerServerConnectionStatus(PEER_SERVER_CONNECTION_STATUS.CONNECTED);
    });

    peer.on("disconnected", () => {
      this.debug("peer disconnected");
    });

    peer.on("close", () => {
      this.debug("peer close");

      this.setPeerServerConnectionStatus(PEER_SERVER_CONNECTION_STATUS.CLOSED);
    });

    peer.on("error", (error) => {
      console.error("Peer error", error);
      this.debug("peer error - current peer status:", this.peerServerConnectionStatus);

      switch (error.type) {
        case "network": // handle "no connection to server"
          if (
            this.peerServerConnectionStatus === PEER_SERVER_CONNECTION_STATUS.CONNECTED ||
            this.peerServerConnectionStatus === PEER_SERVER_CONNECTION_STATUS.RECONNECTING
          ) {
            this.tryReconnect();
            return;
          } else {
            // TODO: Proper handling for DISCONNECTED status.
            // TODO: This should likely CLOSE the peer and not just set the status...
            this.setPeerServerConnectionStatus(PEER_SERVER_CONNECTION_STATUS.DISCONNECTED);
          }
          break;
      }

      this.dispatch("peer_error", error);
    });

    window.addEventListener("offline", () => {
      this.debug("browser network mode: offline - closing peer connection");
      this.peer?.socket.close();
      // we need to emit disconnected here, as the peerjs library does not
      // emit it when the socket is closed by calling it via socket.close()
      this.peer?.socket.emit(SocketEventType.Disconnected);
    });

    window.addEventListener("online", () => {
      this.debug("browser network mode: online");
    });

    this.peer = peer;

    this.dispatch("peer_created", peer);
  }

  onPeerCreated(callback: (peer: Peer) => void) {
    this.on("peer_created", callback);
  }

  onPeerServerConnectionStatusChange(callback: (data: { status: PeerServerConnectionStatus }) => void) {
    return this.on("peer_server_connection_status_change", callback);
  }

  onPeerError(callback: (error: unknown) => void): void {
    this.on("peer_error", (error) => {
      callback(error);
    });
  }

  getPeer(): Peer {
    if (!this.peer) {
      throw new Error("Peer not initialized");
    }
    return this.peer;
  }

  getPeerId(): string {
    return this.getPeer().id || this.peerId || "";
  }

  getPeerServerConnectionStatus(): PeerServerConnectionStatus {
    return this.peerServerConnectionStatus;
  }
}

export default PeerManager;
