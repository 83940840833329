import { CircularArray } from "../../../dataStructures/CircularArray";
import { HostClientEventMessage } from "../../host/HostClient";
import PlayerPlugin, { PlayerPluginEvents } from "../PlayerPlugin";
import { PING_INTERVAL } from "./constants";
import { DataPoint } from "./types";

const DATA_POINT_HISTORY_LENGTH = 120;

export type LatencyPlayerPluginEvents = PlayerPluginEvents & {
  update: (data: { dataPoint: DataPoint; dataPoints: CircularArray<DataPoint> }) => void;
};

class LatencyPlayerPlugin extends PlayerPlugin<LatencyPlayerPluginEvents> {
  constructor() {
    super("LatencyPlayerPlugin");
  }

  private pingSentAt = 0;
  private intervalHandle: ReturnType<typeof setTimeout> | null = null;
  private pingIdCounter = 0;
  private dataPoints: CircularArray<DataPoint> = new CircularArray(DATA_POINT_HISTORY_LENGTH);

  private sendPing = () => {
    this.pingIdCounter++;

    this.getClient().sendEventToHostClient("ping", this.pingIdCounter, false);
    this.pingSentAt = Date.now();
    this.dataPoints.push({
      id: this.pingIdCounter,
      timestamp: this.pingSentAt,
      latency: PING_INTERVAL, // init with max ping, in case the packet is lost, count it as max ping
    });
  };

  private handlePong = (id: number) => {
    if (id < this.pingIdCounter) {
      return; // not latest, so ignore
    }

    const latency = Date.now() - this.pingSentAt;
    const currentDataPoint = this.dataPoints[this.dataPoints.length - 1];
    currentDataPoint.latency = latency;

    this.dispatch("update", {
      dataPoint: currentDataPoint,
      dataPoints: this.dataPoints,
    });
  };

  start = () => {
    if (this.intervalHandle) {
      console.warn("LatencyPlayerPlugin already started");
      return;
    }

    this.intervalHandle = setInterval(() => {
      this.sendPing();
    }, PING_INTERVAL);
  };

  stop = () => {
    if (this.intervalHandle) {
      clearInterval(this.intervalHandle);
      this.intervalHandle = null;
    }
  };

  isStarted = () => {
    return Boolean(this.intervalHandle);
  };

  handleHostClientEvent = (event: HostClientEventMessage) => {
    if (event.eventName === "pong") {
      const pingId = event.data as number;
      this.handlePong(pingId);
    }
  };
}

export default LatencyPlayerPlugin;
