import { H3 } from "../../../../common/ui/heading/Heading";

const PlayerJoinNextGameView = () => {
  return (
    <div className="absolute inset-0 flex items-center justify-center bg-black">
      <H3>Joining on next game...</H3>
    </div>
  );
};

export default PlayerJoinNextGameView;
