import { useEffect, useState } from "react";

/**
 * setState, but it returns to defaultValue after given duration.
 */
export const useTimedState = <T>(defaultValue: T, resetToDefaultMs: number) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (value !== defaultValue) {
      const timeout = setTimeout(() => {
        setValue(defaultValue);
      }, resetToDefaultMs);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [defaultValue, value, resetToDefaultMs]);

  return [value, setValue] as const;
};
