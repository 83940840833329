import { CLIENT_CONNECTION_STATUS } from "../../../common/client/constants";
import HostClient from "../../../common/client/host/HostClient";
import { H3 } from "../../../common/ui/heading/Heading";
import PlayerCard from "../player/PlayerCard";

import { useHostStore } from "../useHostStore";

type Props = {
  isPaused: boolean;
};

const GameSetupStatus = ({ isPaused }: Props) => {
  useHostStore((state) => state.clientConnectionStatusByPeerId); // TODO: This is for connection inactivity updates. We should get player connection status via player object directly?
  useHostStore((state) => state.players);
  const waitingGameSetupPlayerIds = useHostStore((state) => state.waitingGameSetupPlayerIds);
  const waitingForHost = waitingGameSetupPlayerIds.includes(0);
  const waitingForPlayers = waitingGameSetupPlayerIds.length > 0;

  const playersSortedByPerformance = HostClient.party.getPlayersSortedByPerformance();

  return (
    <div
      className={`flex flex-col items-center space-y-16 transition-all duration-500 ${!waitingForPlayers && !waitingForHost && !isPaused ? "opacity-0" : ""}`}
    >
      <div className={`flex space-x-2 py-4`}>
        {playersSortedByPerformance.map((player) => {
          const isReady = !waitingGameSetupPlayerIds.includes(player.getPlayerId());
          const isInactive =
            HostClient.getPlayerConnectionStatus(player.getPlayerId()) === CLIENT_CONNECTION_STATUS.CONNECTED_INACTIVE;

          return (
            <PlayerCard key={player.getPlayerId()} player={player} isInactive={isInactive}>
              {isReady ? (
                <span className="text-green-500">Ready</span>
              ) : (
                <span className="text-orange-500">{isInactive ? "Inactive" : "Waiting"}</span>
              )}
            </PlayerCard>
          );
        })}
      </div>
      {waitingForHost && <H3 className="italic shadow-black text-shadow">Preparing game...</H3>}
    </div>
  );
};

export default GameSetupStatus;
