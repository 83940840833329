import { PlaylistBlueprintData } from "./types";

export const PLAYLIST_BLUEPRINT_DATA: Array<PlaylistBlueprintData> = [
  {
    id: "all_games_randomized_playlist",
    name: "Random Games",
    descriptionShort: "All games in one playlist.",
    descriptionLong: "All games in one playlist.",
    gameEntryIds: [
      // TODO: When we have more games, we should have option to select number of games for randomization, instead of this predefined list
      // TODO: and if we want voting, we need to be able to have vote entries.
      "runman:dungeon",
      "sumo:ring",
      "sumo:lastCall",
      "sumo:king",
      "partycars:blocks",
      "partycars:raft",
      "partycars:parking_fill",
      "partycars:parking_collect",
      "partycars:heist_bank",
      "runman:run",
      "abstract:balance",
      "abstract:bubble",
      "kinderzeichnung:fiesta",
      "spacerace:race",
    ],
    numberOfRounds: 3,
    isRandomizedGameOrder: true,
  },
  {
    id: "dev_playlist",
    name: "dev_playlist",
    descriptionShort: "dev_playlist",
    descriptionLong: "dev_playlist",
    gameEntryIds: ["testgame", "testgame"],
    numberOfRounds: 1,
  },
  {
    id: "all_games_playlist",
    name: "All Games",
    descriptionShort: "All games in one playlist.",
    descriptionLong: "All games in one playlist.",
    gameEntryIds: [
      "runman:run",
      "partycars:parking_collect",
      "kinderzeichnung:fiesta",
      "partycars:heist_bank",
      "abstract:balance",
      "abstract:bubble",
      "spacerace:race",
      "runman:dungeon",
      "partycars:blocks",
      "sumo:ring",
      "sumo:lastCall",
      "sumo:king",
      "partycars:raft",
      "partycars:parking_fill",
    ],
    numberOfRounds: 3,
  },
  {
    id: "sumo_playlist",
    name: "Sumo All",
    descriptionShort: "All the Sumo classics in one playlist.",
    descriptionLong: "All the Sumo classics in one playlist.",
    gameEntryIds: ["sumo:lastCall", "sumo:ring", "sumo:king"],
    numberOfRounds: 5,
  },
  {
    id: "partycars_playlist",
    name: "Party Cars All",
    descriptionShort: "All Party Cars games in one playlist.",
    descriptionLong: "All Party Cars games in one playlist, bring your e-driving lisente!",
    gameEntryIds: [
      "partycars:parking_collect",
      "partycars:blocks",
      "partycars:raft",
      "partycars:parking_fill",
      "partycars:heist_bank",
    ],
    numberOfRounds: 3,
  },
  {
    id: "pong_playlist",
    name: "Just Pong",
    descriptionShort: "Pong Playlist.",
    descriptionLong: "Pong Playlist.",
    gameEntryIds: ["four_pong"],
    numberOfRounds: 6,
  },
] as const;
