import { assert } from "../../logger/assert";
import GameEntry, { GameEntryData } from "../GameEntry";
import GameManager, { GameManagerEvents } from "../GameManager";
import { PlayerClient } from "./PlayerClient";

export type PlayerGameManagerEvents = GameManagerEvents & {
  setup_game: (data: { gameEntry: GameEntry }) => void;
  setup_game_done: (data: { gameEntry: GameEntry }) => void;
  unload_game: () => void;
};

class PlayerGameManager extends GameManager<PlayerGameManagerEvents> {
  protected client: PlayerClient | null = null;

  setup(client: PlayerClient): void {
    this.client = client;

    client.hostClientEvents.on("setup_game", (data) => {
      const gameEntry = new GameEntry(data.gameEntry);
      if (this.gameSetupDone) {
        assert(this.gameEntry, "expected game entry as gameSetupDone is true");
        if (this.gameEntry.getGameEntryId() !== gameEntry.getGameEntryId()) {
          throw new Error(
            `Another game already set up. Unload previous game first. Currently setup game: ${this.gameEntry.getGameEntryId()}, trying to setup new game: ${gameEntry.getGameEntryId()}`,
          );
        }

        this.dispatch("setup_game_done", { gameEntry: this.gameEntry });
        return;
      }

      this.setupGame(gameEntry);
    });
    client.hostClientEvents.on("unload_game", () => {
      this.unloadGame();
    });
    client.frameEvents.on("setup_game_frame_done", () => {
      this.setupDone();
    });
  }

  protected getClient() {
    assert(this.client, "Client not set");
    return this.client;
  }

  private setupGame(gameEntry: GameEntry) {
    if (this.gameEntry) {
      throw new Error("Game already set up. Unload previous game first.");
    }

    this.gameEntry = gameEntry;
    this.gameSetupDone = false;

    this.dispatch("setup_game", { gameEntry });
  }

  public setupGameEntryFromData(gameEntryData: GameEntryData) {
    this.setupGame(new GameEntry(gameEntryData));
  }

  private unloadGame() {
    this.gameEntry = null;
    this.gameSetupDone = false;

    this.dispatch("unload_game");
  }
}

export default PlayerGameManager;
