import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
  WifiIcon,
} from "@heroicons/react/24/solid";
import HostClient from "../../common/client/host/HostClient";
import { useHostStore } from "./useHostStore";

import { PEER_SERVER_CONNECTION_STATUS } from "../../common/client/PeerManager";
import useFullScreen from "../../common/fullScreen/useFullScreen";
import Button from "../../common/ui/button/Button";
import { useRadio } from "./radio/useRadio";

type Props = {
  showMuteButton?: boolean;
  showFullScreenButton?: boolean;
  showPartyId?: boolean;
};

const HeaderOverlay = ({ showMuteButton, showFullScreenButton, showPartyId }: Props) => {
  const fullScreen = useFullScreen();
  const radio = useRadio();
  useHostStore((state) => state.playerCount); // re-render on player count change
  useHostStore((state) => state.clientConnectionStatusByPeerId);
  const peerServerConnectionStatus = useHostStore((state) => state.peerServerConnectionStatus);

  const partyId = HostClient.getPartyId(true);
  const peerServerConnectionProblem = peerServerConnectionStatus !== PEER_SERVER_CONNECTION_STATUS.CONNECTED;

  return (
    <div className="pointer-events-none absolute left-0 right-0 z-headerOverlay flex justify-end space-x-2 p-2">
      {peerServerConnectionProblem && (
        <div className="mr-3 flex py-3 text-red-500">
          <WifiIcon className="mr-2 h-6 w-6" />
          {peerServerConnectionStatus}
        </div>
      )}
      {showPartyId && (
        <div className="items-center justify-center rounded-lg bg-stone-900 px-2 py-1 ">
          <div
            className="pointer-events-auto cursor-pointer text-3xl font-bold text-green-600"
            onClick={() => {
              void navigator.clipboard.writeText(partyId);
            }}
          >{`${partyId}`}</div>
        </div>
      )}
      {showMuteButton && radio.isEnabled && (
        <Button
          className="pointer-events-auto cursor-pointer"
          onClick={() => {
            void radio.toggleMute();
          }}
        >
          {!radio.isMuted ? <SpeakerWaveIcon className="h-6 w-6" /> : <SpeakerXMarkIcon className="h-6 w-6" />}
        </Button>
      )}
      {showFullScreenButton && (
        <Button
          className="pointer-events-auto cursor-pointer"
          onClick={() => {
            void fullScreen.toggle();
          }}
        >
          {!fullScreen.isFullscreen ? (
            <ArrowsPointingOutIcon className="h-6 w-6" />
          ) : (
            <ArrowsPointingInIcon className="h-6 w-6" />
          )}
        </Button>
      )}
    </div>
  );
};

export default HeaderOverlay;
