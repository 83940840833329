import { useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";

type Props = {
  children: () => React.ReactNode;
  enabled: boolean;
  rootPath: string;
};

const ForceRootRouteEntry = ({ children, enabled, rootPath }: Props) => {
  const location = useLocation();
  const hasVisitedRoot = useRef(false);

  if (location.pathname === rootPath) {
    hasVisitedRoot.current = true;
  }

  if (enabled && !hasVisitedRoot.current && location.pathname !== rootPath) {
    return <Navigate to={rootPath} />;
  }

  return children();
};

export default ForceRootRouteEntry;
