import { useCallback, useEffect, useState } from "react";

import { animals, uniqueNamesGenerator } from "unique-names-generator";
import { PEER_SERVER_CONNECTION_STATUS } from "../../common/client/PeerManager";
import { CLIENT_CONNECTION_STATUS, HOST_PEER_ID_PREFIX } from "../../common/client/constants";
import HostClient from "../../common/client/host/HostClient";
import { MAX_PLAYER_NAME_LENGTH } from "../../common/client/host/constants";
import { storages } from "../../common/storage/storage";
import Button from "../../common/ui/button/Button";
import clientLocalStorage from "../storage/clientLocalStorage";
import useDevAutoConnect from "./dev/useDevAutoConnect";
import useDevAutofillPartyId from "./dev/useDevAutofillPartyId";
import { usePlayerStore } from "./usePlayerStore";
import { IS_DEV } from "../constants";

function createRandomizedPlayerName() {
  let attempts = 100;
  while (attempts > 0) {
    const name = uniqueNamesGenerator({
      dictionaries: [animals],
      separator: " ",
      style: "capital",
    });
    if (name.length <= MAX_PLAYER_NAME_LENGTH) {
      return name;
    }
    attempts--;
  }

  return "";
}

function getPlayerName(isReconnectView = false) {
  if (IS_DEV && !isReconnectView) return createRandomizedPlayerName();

  return clientLocalStorage.getItem("playerName") || "";
}

function renderPeerServerConnectionStatus(peerServerConnectionStatus: string | null) {
  switch (peerServerConnectionStatus) {
    case PEER_SERVER_CONNECTION_STATUS.CONNECTING:
      return "Connecting to server...";
    case PEER_SERVER_CONNECTION_STATUS.RECONNECTING:
      return "Reconnecting to server...";
    case PEER_SERVER_CONNECTION_STATUS.RECONNECTING_FAILED:
    case PEER_SERVER_CONNECTION_STATUS.DISCONNECTED:
      return "Could not connect to the server, refresh to try again.";
  }

  return null;
}

const PlayerConnectView = () => {
  const devAutofillPartyId = useDevAutofillPartyId();
  let previousPartyId = clientLocalStorage.getItem("partyId");
  previousPartyId = previousPartyId ? HostClient.formatPartyId(previousPartyId) : "";
  const previousName = clientLocalStorage.getItem("playerName");
  const actions = usePlayerStore((state) => state.actions);
  const peerServerConnectionStatus = usePlayerStore((state) => state.peerServerConnectionStatus);
  const connectionStatus = usePlayerStore((state) => state.clientConnectionStatus);
  const [partyId, setPartyId] = useState(previousPartyId || "");
  const [isReconnectView, setIsReconnectView] = useState(!!previousPartyId && !!previousName);
  const [playerName, setPlayerName] = useState(getPlayerName(isReconnectView));

  const setPlayerNameFormatted = (name: string) => {
    setPlayerName(name.substring(0, MAX_PLAYER_NAME_LENGTH));
  };

  const setPartyIdFormatted = (partyId: string) => {
    setPartyId(HostClient.formatPartyId(partyId));
  };

  useEffect(() => {
    if (!!devAutofillPartyId && devAutofillPartyId !== partyId) {
      setIsReconnectView(false);
      setPartyIdFormatted(devAutofillPartyId);
      setPlayerNameFormatted(getPlayerName());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devAutofillPartyId]);

  const connectedToPeerServer = peerServerConnectionStatus === PEER_SERVER_CONNECTION_STATUS.CONNECTED;
  const connectingToPeerServer =
    peerServerConnectionStatus &&
    [PEER_SERVER_CONNECTION_STATUS.CONNECTING, PEER_SERVER_CONNECTION_STATUS.RECONNECTING].includes(
      peerServerConnectionStatus,
    );
  const connectingToPeer = connectionStatus === CLIENT_CONNECTION_STATUS.CONNECTING;

  const handleConnect = useCallback(() => {
    const connectPartyId = `${HOST_PEER_ID_PREFIX}${partyId}`.replace(/ /g, "");
    storages.local.setItem("devAutofillPartyId", `${HOST_PEER_ID_PREFIX}${partyId}`);
    actions.connect(connectPartyId, playerName);
  }, [actions, partyId, playerName]);

  useDevAutoConnect(handleConnect);

  const disableConnectButton =
    !connectedToPeerServer || connectingToPeer || connectingToPeerServer || !partyId || !playerName;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        width: "100%",
        height: "100%",
        gap: "20px",
      }}
      className="flex h-full w-full flex-col items-center justify-start gap-20 pt-20"
    >
      <input
        type="text"
        inputMode="numeric"
        pattern="[0-9]+"
        placeholder="6-DIGIT CODE"
        className="mt-12 max-w-64 rounded-lg p-3 text-center text-3xl font-bold text-[#373737] ring-8 ring-[#373737]"
        onChange={(e) => setPartyIdFormatted(e.target.value)}
        value={partyId}
        disabled={connectingToPeer || isReconnectView}
      />
      <input
        type="text"
        placeholder="Player Name"
        className="max-w-64 rounded-lg p-3 text-center text-3xl font-bold text-[#373737] ring-8 ring-[#373737]"
        onChange={(e) => setPlayerNameFormatted(e.target.value)}
        maxLength={MAX_PLAYER_NAME_LENGTH}
        value={playerName}
        disabled={connectingToPeer || isReconnectView}
      />
      <br />
      <div className="flex w-full max-w-60 flex-col gap-6">
        {isReconnectView ? (
          <>
            <Button onClick={handleConnect} disabled={disableConnectButton} className="w-full">
              Reconnect
            </Button>
            <Button
              onClick={() => {
                clientLocalStorage.removeItem("partyId");
                setPartyIdFormatted("");
                setIsReconnectView(false);
              }}
              disabled={connectingToPeer || connectingToPeerServer || !partyId || !playerName}
              className="w-full"
              variant="secondary"
            >
              Join Another Party
            </Button>
          </>
        ) : (
          <Button onClick={handleConnect} disabled={disableConnectButton} className="w-full">
            Join the Party
          </Button>
        )}
      </div>
      {connectingToPeer && <div>Connecting...</div>}
      {!connectedToPeerServer && (
        <div className="px-4 text-center">{renderPeerServerConnectionStatus(peerServerConnectionStatus)}</div>
      )}
    </div>
  );
};

export default PlayerConnectView;
