import EventEmitter from "@shared/src/gc/events/EventEmitter";
import { assert } from "../logger/assert";
import { BaseClientType } from "./BaseClient";
import GameEntry from "./GameEntry";

export type GameManagerEvents = Record<string, never>;

class GameManager<T extends GameManagerEvents> extends EventEmitter<T> {
  protected client: BaseClientType | null = null;
  protected gameEntry: GameEntry | null = null;
  protected gameSetupDone: boolean = false;

  protected setupDone() {
    this.gameSetupDone = true;
    this.dispatch("setup_game_done", { gameEntry: this.gameEntry });
  }

  setup(client: BaseClientType): void {
    this.client = client;
  }

  protected getClient() {
    assert(this.client, "Client not set");
    return this.client;
  }

  getGameEntry() {
    return this.gameEntry;
  }

  isGameSetupDone() {
    return this.gameSetupDone;
  }
}

export default GameManager;
