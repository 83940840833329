import { useState } from "react";
import useCountdown from "../../../common/hook/useCountdown";
import { SOUNDS, useSoundOnMount } from "../../sounds/ui/sounds";

const Notification = ({
  durationSeconds,
  message,
  onExit,
}: {
  durationSeconds: number;
  message: string;
  onExit: () => void;
}) => {
  const [show, setShow] = useState(true);
  const countdownSeconds = useCountdown(
    durationSeconds,
    () => {
      setShow(false);
      onExit();
    },
    show,
  );
  useSoundOnMount(SOUNDS.NOTIFICATION_APPEAR);

  if (!show) {
    return null;
  }

  const animateOut = countdownSeconds <= 1 ? true : false;

  if (countdownSeconds === 0) {
    return null;
  }

  return (
    <div className="absolute inset-0 overflow-hidden">
      <div
        className={`pointer-events-none absolute inset-0 z-hostNotificationOverlay overflow-hidden fill-mode-forwards ${animateOut ? "duration-200 ease-in animate-out fade-out-0 slide-out-to-top-1/4" : "duration-200 ease-out animate-in fade-in-100 slide-in-from-top-1/4"}`}
      >
        <div className="animated-background-shine absolute left-1/2 top-[20%] -translate-x-1/2 -translate-y-1/2 transform rounded-full border-8 border-lime-100 border-opacity-90 bg-indigo-600 bg-opacity-80 bg-gradient-to-r from-indigo-800 via-indigo-400 to-indigo-700 px-8 py-6 shadow-lg">
          <div className="text-2xl font-bold text-white">{message}</div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
