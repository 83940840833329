import { DataConnection } from "peerjs";
import { assert } from "../../logger/assert";
import { waitUntilTrue } from "../../utils/promise";
import ClientConnection from "../ClientConnection";
import { CLIENT_CONNECTION_STATUS } from "../constants";
import { PLAYER_CONNECT_TYPE, PlayerConnectMetadata } from "../host/HostPeerManager";
import { PEER_SERVER_CONNECTION_STATUS } from "./../PeerManager";
import PlayerPeerManager from "./PlayerPeerManager";

export type PlayerConnectionStats = {
  remoteCandidate: {
    protocol: string;
    type: string;
  };
  localCandidate: {
    protocol: string;
    networkType: string;
    type: string;
  };
};

export type PlayerClientConnectionStats = {
  reliable: PlayerConnectionStats;
  unreliable: PlayerConnectionStats;
};

type ConnectionParams = {
  peerId: string;
  options: ConnectionOptions;
};

type ConnectionOptions = {
  playerName: string;
};

class PlayerClientConnection extends ClientConnection {
  protected peerManager: PlayerPeerManager;

  private prevConnectionParams: ConnectionParams | null = null;

  constructor(peerManager: PlayerPeerManager) {
    super(peerManager);
    this.peerManager = peerManager;
  }

  private getConnectionStats = async (connection: DataConnection) => {
    this.debug("getConnectionStats() - getting stats...");

    const connectionStats: PlayerConnectionStats = {
      remoteCandidate: {
        protocol: "-",
        type: "-",
      },
      localCandidate: {
        protocol: "-",
        networkType: "-",
        type: "-",
      },
    };

    await connection.peerConnection.getStats().then((stats) => {
      stats.forEach((row: { type: string; candidateType: string; protocol: string; networkType: string }) => {
        switch (row.type) {
          case "remote-candidate":
            connectionStats.remoteCandidate = {
              protocol: row.protocol,
              type: row.candidateType,
            };
            break;
          case "local-candidate":
            connectionStats.localCandidate = {
              protocol: row.protocol,
              networkType: row.networkType,
              type: row.candidateType,
            };
            break;
        }
      });
    });

    this.debug("getConnectionStats() - finished - stats: ", connectionStats);

    return connectionStats;
  };

  private updateConnectionStats = async () => {
    assert(this.reliableConnection, "reliableConnection expected");
    assert(this.unreliableConnection, "unreliableConnection expected");

    const reliableConnectionStats = await this.getConnectionStats(this.reliableConnection);
    const unreliableConnectionStats = await this.getConnectionStats(this.unreliableConnection);

    const stats: PlayerClientConnectionStats = {
      reliable: reliableConnectionStats,
      unreliable: unreliableConnectionStats,
    };

    this.dispatch("peer_connection_stats", stats);
  };

  private setupCommonConnectionEvents = (connection: DataConnection) => {
    void connection.peerConnection.addEventListener(
      "iceconnectionstatechange",
      (event) => {
        const rtcPeerConnection = event.currentTarget as RTCPeerConnection;
        this.debug("iceconnectionstatechange - new state: ", rtcPeerConnection.iceConnectionState);
        if (rtcPeerConnection.iceConnectionState === "disconnected") {
          void this.trySilentAutoReconnect();
        }
        //   }
        // }
      },
      false,
    );

    void connection.peerConnection.addEventListener(
      "selectedcandidatepairchange",
      () => {
        void this.updateConnectionStats();
      },
      false,
    );
  };

  async trySilentAutoReconnect() {
    this.debug("trySilentAutoReconnect()");

    // PeerJS connection does not have a way to recover from ice disconnected state.
    //
    // Close old connections by making sure they don't trigger events any more,
    // as we will replace them with new PeerJS connections on the fly "silently".
    // Anything outside PlayerClientConnection should not be aware of this reconnection
    // on the PlayerClient side. The HostClient side will of course see the connection as a new one,
    // and needs to handle it accordingly.

    this.reliableConnection?.removeAllListeners();
    this.reliableConnection?.close();
    this.reliableConnection = null;

    this.unreliableConnection?.removeAllListeners();
    this.unreliableConnection?.close();
    this.unreliableConnection = null;

    this.debug("Check that we have WebSocket connection to peer server...");
    const success = await waitUntilTrue(
      () => this.peerManager.getPeerServerConnectionStatus() === PEER_SERVER_CONNECTION_STATUS.CONNECTED,
    );
    if (!success) {
      throw new Error(
        `Peer not connected, can't reconnect client connections. Peer status: ${this.peerManager.getPeerServerConnectionStatus()}`,
      );
    }

    assert(this.prevConnectionParams, "prevConnectionParams expected");
    this.connect(this.prevConnectionParams.peerId, this.prevConnectionParams.options, true);
  }

  connect(peerId: string, options: { playerName: string }, isSilentAutoReconnect = false) {
    this.debug("connect() - peerId: ", peerId, "options: ", options);
    if (this.reliableConnection) {
      this.debug("connect() - can't connect: reliableConnection already exists");
      this.dispatch("error", new Error("Reliable connection already exists"));
      return;
    }

    this.prevConnectionParams = {
      peerId,
      options,
    };

    !isSilentAutoReconnect && this.setStatus(CLIENT_CONNECTION_STATUS.CONNECTING);

    const connectMetadata: PlayerConnectMetadata = {
      type: isSilentAutoReconnect ? PLAYER_CONNECT_TYPE.SILENT_AUTO_RECONNECT : PLAYER_CONNECT_TYPE.JOIN,
      playerName: options.playerName,
    };

    const reliableConnection = this.openReliableConnection(peerId, connectMetadata);
    if (!reliableConnection) {
      this.debug("connect() - could not open reliableConnection");
      return;
    }

    this.debug("connect() - waiting for reliableConnection open...");

    reliableConnection.on("open", () => {
      this.debug("connect() - reliableConnection open");

      this.setupCommonConnectionEvents(reliableConnection);

      const unreliableConnection = this.openUnreliableConnection(peerId, connectMetadata);
      assert(unreliableConnection, "unreliableConnection expected");

      unreliableConnection.on("open", () => {
        this.debug("connect() - unreliableConnection open");

        this.setupCommonConnectionEvents(unreliableConnection);

        if (!isSilentAutoReconnect) {
          this.setStatus(CLIENT_CONNECTION_STATUS.CONNECTED_ACTIVE);
          this.dispatch("connected", {
            metadata: reliableConnection.metadata,
          });
        }

        void this.updateConnectionStats();
      });
    });
  }

  private openReliableConnection(peerId: string, connectMetadata: PlayerConnectMetadata) {
    this.debug("openReliableConnection() - peerId: ", peerId);

    if (this.reliableConnection) {
      this.dispatch("error", new Error("Reliable connection already exists"));
      return;
    }

    const peer = this.getPeer();
    const connection = peer.connect(peerId, {
      reliable: true,
      metadata: connectMetadata,
    });
    this.reliableConnection = connection;
    this.setupCommonConnectionCallbacks(connection);

    return connection;
  }

  private openUnreliableConnection(peerId: string, connectMetadata: PlayerConnectMetadata) {
    this.debug("openUnreliableConnection() - peerId: ", peerId);

    if (this.unreliableConnection) {
      this.dispatch("error", new Error("Unreliable connection already exists"));
      return;
    }

    const peer = this.getPeer();
    const connection = peer.connect(peerId, {
      reliable: false,
      metadata: connectMetadata,
    });
    this.unreliableConnection = connection;
    this.setupCommonConnectionCallbacks(connection);

    return connection;
  }

  getPeer() {
    return this.peerManager.getPeer();
  }
}

export default PlayerClientConnection;
