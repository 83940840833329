import EventEmitter, { DefaultListener } from "@shared/src/gc/events/EventEmitter";
import { assert } from "../../logger/assert";

type GameResult = {
  playlistId: number;
  gameId: string;
  playerIdsByPlacement: number[];
};

export type PlayerPlaylistStats = {
  firstPlaces: number;
  performance: number;
  numberOfGames: number;
};

export type PlayerStats = {
  firstPlaces: number;
  performance: number;
  numberOfGames: number;
  playlistWins: number;
  numberOfPlaylists: number;
  statsByPlaylistId: Map<number, PlayerPlaylistStats>;
};

interface PartyStatsEvents extends DefaultListener {
  stats_change: () => void;
}

class PartyStats<T extends PartyStatsEvents = PartyStatsEvents> extends EventEmitter<T> {
  private gameResults: GameResult[] = [];
  private playerStatsByPlayerId: Map<number, PlayerStats> = new Map();
  private playersStatsByPlaylistId: Map<number, Map<number, PlayerPlaylistStats>> = new Map();

  private calculatePlayerStats(playerId: number, gameResults: GameResult[]) {
    const stats: PlayerStats = {
      firstPlaces: 0,
      performance: -1,
      numberOfGames: 0,
      playlistWins: 0, // this can't be calculated in this fnc, will be calculated later
      numberOfPlaylists: 0,
      statsByPlaylistId: new Map(),
    };

    const statsByPlaylistId: Map<number, PlayerPlaylistStats> = new Map();
    stats.statsByPlaylistId = statsByPlaylistId;

    const performanceSumByPlaylistId: Map<number, number> = new Map();
    let performanceSum = 0;

    gameResults.forEach((gameResult) => {
      const placement = gameResult.playerIdsByPlacement.indexOf(playerId);

      const playerNotInGame = placement === -1;
      if (playerNotInGame) {
        return;
      }

      const playlistId = gameResult.playlistId;
      const playlistStats = statsByPlaylistId.get(playlistId) || {
        firstPlaces: 0,
        performance: -1,
        numberOfGames: 0,
      };
      statsByPlaylistId.set(playlistId, playlistStats);

      if (placement === 0) {
        stats.firstPlaces++;
        playlistStats.firstPlaces++;
      }

      stats.numberOfGames++;
      playlistStats.numberOfGames++;

      const playerCount = gameResult.playerIdsByPlacement.length;
      const performance = (playerCount - 1 - placement) / (playerCount - 1);
      performanceSum += performance;

      performanceSumByPlaylistId.set(playlistId, (performanceSumByPlaylistId.get(playlistId) || 0) + performance);
    });

    stats.performance = stats.numberOfGames > 0 ? performanceSum / stats.numberOfGames : 0;

    statsByPlaylistId.forEach((playlistStats, playlistId) => {
      const totalPerformance = performanceSumByPlaylistId.get(playlistId);
      const numberOfGames = playlistStats.numberOfGames;
      assert(totalPerformance !== undefined, "totalPerformance expected");
      playlistStats.performance = numberOfGames > 0 ? totalPerformance / numberOfGames : 0;
    });

    stats.numberOfPlaylists = statsByPlaylistId.size;

    return stats;
  }

  calculateAndStoreStatsForPlayers(playerIds: number[]) {
    this.calculateStatsForPlayers(playerIds, 0, this.playerStatsByPlayerId);
    this.dispatch("stats_change");
  }

  calculateStatsForPlayers(playerIds: number[], gameOffset = 0, playerStatsByPlayerId: Map<number, PlayerStats>) {
    if (gameOffset > 0) {
      throw new Error("gameOffset needs to be negative or zero value");
    }

    const gameResults = this.gameResults.slice(0, this.gameResults.length + gameOffset);

    playerIds.forEach((playerId) => {
      const stats = this.calculatePlayerStats(playerId, gameResults);

      playerStatsByPlayerId.set(playerId, stats);

      stats.statsByPlaylistId.forEach((playlistStats, playlistId) => {
        const statsByPlayerId: Map<number, PlayerPlaylistStats> =
          this.playersStatsByPlaylistId.get(playlistId) || new Map<number, PlayerPlaylistStats>();
        statsByPlayerId.set(playerId, playlistStats);
        this.playersStatsByPlaylistId.set(playlistId, statsByPlayerId);
      });
    });

    // Try catch just in case this causes errors still. Should not, but it could ruin game session and results.
    try {
      const playlistWinsByPlayerId: Map<number, number> = new Map();

      this.playersStatsByPlaylistId.forEach((playerPlaylistStatsByPlayerId) => {
        const sortedByPerformance = Array.from(playerPlaylistStatsByPlayerId.entries()).sort(
          (a, b) => b[1].performance - a[1].performance,
        );

        const firstPlayerId = sortedByPerformance[0][0];
        const playlistWins = playlistWinsByPlayerId.get(firstPlayerId) || 0;
        playlistWinsByPlayerId.set(firstPlayerId, playlistWins + 1);
      });

      playlistWinsByPlayerId.forEach((playlistWins, playerId) => {
        const stats = playerStatsByPlayerId.get(playerId);
        if (!stats) return;

        stats.playlistWins = playlistWins;
      });
    } catch (e) {
      console.error(e);
    }
  }

  addGameResult(gameResult: GameResult) {
    this.gameResults.push(gameResult);
    this.calculateAndStoreStatsForPlayers(gameResult.playerIdsByPlacement);
  }

  getLatestGameResults() {
    return this.gameResults[this.gameResults.length - 1];
  }

  getStats() {
    return this.playerStatsByPlayerId;
  }

  getPlaylistStatsByPlayerIdMap(playlistId: number, gameOffset = 0) {
    if (gameOffset > 0) {
      throw new Error("gameOffset needs to be negative value");
    }

    const results = this.getLatestGameResults();

    if (!results) {
      return new Map<number, PlayerPlaylistStats>();
    }

    let playerStatsByPlayerId: Map<number, PlayerStats>;
    if (gameOffset == 0) {
      playerStatsByPlayerId = this.playerStatsByPlayerId;
    } else {
      playerStatsByPlayerId = new Map();
      this.calculateStatsForPlayers(results.playerIdsByPlacement, gameOffset, playerStatsByPlayerId);
    }

    return results.playerIdsByPlacement.reduce((acc, playerId) => {
      const playerStats: PlayerStats = playerStatsByPlayerId.get(playerId) || {
        firstPlaces: 0,
        performance: 0,
        numberOfGames: 0,
        playlistWins: 0,
        numberOfPlaylists: 0,
        statsByPlaylistId: new Map<number, PlayerPlaylistStats>(),
      };

      const playlistStats: PlayerPlaylistStats = playerStats.statsByPlaylistId.get(playlistId) || {
        firstPlaces: 0,
        performance: 0,
        numberOfGames: 0,
      };

      acc.set(playerId, playlistStats);
      return acc;
    }, new Map<number, PlayerPlaylistStats>());
  }
}

export default PartyStats;
