import { EVENT_TYPE } from "@shared/src/gc/events/constants";
import { GameEventMessage } from "@shared/src/gc/events/GameEventEmitter";
import { DataConnection } from "peerjs";
import { assert } from "../../logger/assert";
import { getRandomNumber } from "../../utils/number";
import { ClientConnectionStatus, ConnectionErrorEventData } from "../ClientConnection";
import { CLIENT_CONNECTION_STATUS, PLAYER_PEER_ID_PREFIX } from "../constants";
import { HostClientEventMessage } from "../host/HostClient";
import PeerManager, { PEER_SERVER_CONNECTION_STATUS, PeerManagerEvents } from "../PeerManager";
import PlayerClientConnection, { PlayerClientConnectionStats } from "./PlayerClientConnection";

export type PlayerPeerManagerEvents = PeerManagerEvents & {
  on_peer_ready: () => void;
  connection_status_change: (data: { status: ClientConnectionStatus }) => void;
  peer_connection_stats: (data: PlayerClientConnectionStats) => void;
  error: (error: Error) => void;
};

export type PlayerPeerManagerEventData<T extends keyof PlayerPeerManagerEvents> = Parameters<
  PlayerPeerManagerEvents[T]
>[0];

class PlayerPeerManager extends PeerManager<PlayerPeerManagerEvents> {
  private clientConnection: PlayerClientConnection | null = null;

  private createNewPlayerPeerId() {
    return `${PLAYER_PEER_ID_PREFIX}${getRandomNumber(12)}`;
  }

  public setup(options?: { forceRelay?: boolean }) {
    super.setup();

    this.onPeerCreated((peer) => {
      this.debug("onPeerCreated() - peerId:", peer.id);

      if (this.clientConnection) {
        this.clientConnection.destroy();
      }

      this.clientConnection = new PlayerClientConnection(this);
      this.clientConnection.on("peer_connection_stats", (data) => {
        this.dispatch("peer_connection_stats", data);
      });

      peer.on("connection", (connection: DataConnection) => {
        const noIncomingConnectionsResponse =
          "Player can't accept incoming connections. Player should always initiate connection to host: closing connection";
        this.debug(noIncomingConnectionsResponse);

        void connection.send({
          type: "error",
          message: noIncomingConnectionsResponse,
        });
        connection.close();
      });

      this.dispatch("on_peer_ready");
    });

    this.onPeerError(() => {
      this.closeClientConnection();
    });

    this.createNewPeer(this.createNewPlayerPeerId(), {
      forceRelay: options?.forceRelay,
    });
  }

  private getClientConnection(): PlayerClientConnection {
    assert(this.clientConnection, "Client connection not initialized");
    return this.clientConnection;
  }

  connect(peerId: string, options: { playerName: string }) {
    this.debug("connect() - peerId: ", peerId, "options: ", options);

    assert(this.clientConnection, "Client connection not initialized");

    if (this.getPeerServerConnectionStatus() !== PEER_SERVER_CONNECTION_STATUS.CONNECTED) {
      this.dispatch("error", new Error("Can't connect: Peer is not in connected state"));
      return;
    }

    if (this.clientConnection.getStatus() !== CLIENT_CONNECTION_STATUS.CLOSED) {
      this.dispatch("error", new Error("Can't connect: Connection is not in closed state"));
      return;
    }

    this.clientConnection.onStatusChange((data) => {
      this.dispatch("connection_status_change", data);
    });

    this.clientConnection.connect(peerId, options);
  }

  closeClientConnection(): void {
    this.debug("closeClientConnection()");

    this.clientConnection?.close();
  }

  sendMessage(message: unknown, reliable = true): void {
    void this.getClientConnection().sendMessage(message, reliable);
  }

  onPeerReady(callback: () => void) {
    return this.on("on_peer_ready", callback);
  }

  onConnected(callback: (metadata: unknown) => void) {
    return this.getClientConnection().onConnected(({ metadata }) => {
      callback(metadata);
    });
  }

  onDisconnected(callback: () => void) {
    return this.getClientConnection().onClose(callback);
  }

  onClientConnectionError(callback: (error: ConnectionErrorEventData) => void) {
    return this.getClientConnection().onError((error) => {
      callback(error);
    });
  }

  onData(callback: (data: unknown) => void) {
    return this.getClientConnection().onData((data) => {
      callback(data);
    });
  }

  onGameEvent(callback: (event: GameEventMessage) => void) {
    return this.getClientConnection().onData((data) => {
      if ((data as GameEventMessage).type === EVENT_TYPE.GAME_EVENT) {
        callback(data as GameEventMessage);
      }
    });
  }

  onHostClientEvent(callback: (event: HostClientEventMessage) => void) {
    return this.getClientConnection().onData((data) => {
      if ((data as HostClientEventMessage).type === EVENT_TYPE.HOST_CLIENT_EVENT) {
        callback(data as HostClientEventMessage);
      }
    });
  }
}

export default PlayerPeerManager;
