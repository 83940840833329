import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import GameItem from "./GameItem";
import { CATEGORIES, GAME_ENTRY_DATA } from "../../../../common/client/constants";
import * as arrayUtils from "../../../../common/utils/array";
import { GameEntryData } from "../../../../common/client/GameEntry";
import { IS_DEV } from "../../../constants";
import { setFocus, FocusContext, useFocusable } from "@noriginmedia/norigin-spatial-navigation";

const ITEMS_PER_SLIDE = 4;

const gameEntryData = !IS_DEV
  ? GAME_ENTRY_DATA.filter((ge) => !ge.categories.includes(CATEGORIES.IN_DEVELOPMENT))
  : GAME_ENTRY_DATA;

const GameItems = () => {
  const { ref, focusKey } = useFocusable();
  const navigate = useNavigate();
  const handleGameSelect = (gameEntryData: GameEntryData) => {
    navigate(`/host/games/${gameEntryData.id}`);
  };

  const grid = arrayUtils.chunk(gameEntryData, ITEMS_PER_SLIDE);

  return (
    <div className="relative flex h-full w-full overflow-hidden" ref={ref}>
      <Swiper
        className="absolute bottom-[50px] left-[155px] right-[155px] top-[50px]"
        allowTouchMove={false}
        slidesPerView="auto"
        spaceBetween={20}
        direction="vertical"
      >
        <FocusContext.Provider value={focusKey}>
          {grid.map((row, iy) => (
            <SwiperSlide key={iy}>
              <div className="flex">
                {row.map((gameEntryData, ix) => (
                  <GameItem
                    focusKey={`game-item-${ix}-${iy}`}
                    key={gameEntryData.id}
                    swiperIndex={iy}
                    forceFocus={iy === 0 && ix === 0}
                    gameEntryData={gameEntryData}
                    onSelect={() => handleGameSelect(gameEntryData)}
                    // For some reason, the automatic spatial navigation is unreliable when changing window size.
                    // Here we "manually" set the target within the items grid (based on navigation direction).
                    onActionDirection={(direction) => {
                      let focusTarget = "";
                      switch (direction) {
                        case "up":
                          if (grid[iy - 1]) {
                            focusTarget = `game-item-${ix}-${iy - 1}`;
                          }
                          break;
                        case "right":
                          if (grid[iy][ix + 1]) {
                            focusTarget = `game-item-${ix + 1}-${iy}`;
                          }
                          break;
                        case "down":
                          if (grid[iy + 1]) {
                            // jump to the last row's last item if there is no item below current
                            if (!grid[iy + 1][ix]) {
                              focusTarget = `game-item-${grid[iy + 1].length - 1}-${iy + 1}`;
                              break;
                            }
                            focusTarget = `game-item-${ix}-${iy + 1}`;
                          }
                          break;
                        case "left":
                          if (grid[iy][ix - 1]) {
                            focusTarget = `game-item-${ix - 1}-${iy}`;
                          }
                          break;
                        default:
                          throw new Error(`Unhandled direction: ${direction}`);
                      }

                      if (!focusTarget) return;

                      setFocus(focusTarget);
                    }}
                  />
                ))}
              </div>
            </SwiperSlide>
          ))}
        </FocusContext.Provider>
      </Swiper>
    </div>
  );
};

export default GameItems;
