import useFullScreen from "../../../common/fullScreen/useFullScreen";
import { IS_DEV } from "../../constants";

const FullscreenOverlayButton = () => {
  const fullScreen = useFullScreen();

  if (IS_DEV || !fullScreen.isAvailable || fullScreen.isFullscreen) {
    return null;
  }

  return (
    <button
      className="z-playerFullscreenOverlay absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-95"
      onClick={() => {
        void fullScreen.request();
      }}
    >
      <div className="w-[242px] px-10 pb-4">
        <img className="pointer-events-none h-[105px] select-none" src="/gc-logo.svg" alt="" />
      </div>
      <div className="mt-4 rounded-lg bg-indigo-600 px-4 py-2 uppercase text-white">Tap to go fullscreen</div>
    </button>
  );
};

export default FullscreenOverlayButton;
