import EventEmitter, { DefaultListener } from "@shared/src/gc/events/EventEmitter";
import { createFrameEventMessage } from "@shared/src/gc/events/events";
import FrameEventEmitter, { FrameEventData, FrameEventName } from "@shared/src/gc/events/FrameEventEmitter";
import GameEventEmitter from "../../../../shared/src/gc/events/GameEventEmitter";
import { ConnectionErrorEventData } from "./ClientConnection";
import PlayersManager from "./PlayersManager";
import ClientPlugin from "./plugins/ClientPlugin";
import ForceScreenOrientationPlugin from "./plugins/forceScreenOrientation/ForceScreenOrientationPlugin";

export type UnknownFrameMessage = MessageEvent<{
  data: {
    type?: string;
  };
}>;

export interface BaseClientEvents extends DefaultListener {
  error: (error: unknown) => void;
  peer_error: (error: unknown) => void;
  connection_error: (error: ConnectionErrorEventData) => void;
}

class BaseClient<T extends BaseClientEvents> extends EventEmitter<T> {
  private isHost = false;
  protected setupDone = false;
  protected iframeElement: HTMLIFrameElement | null = null;
  readonly playersManager: PlayersManager;
  readonly frameEvents: FrameEventEmitter;
  readonly gameEvents: GameEventEmitter;
  protected plugins: ClientPlugin[] = [];
  private pluginsByName: Record<string, ClientPlugin> = {};
  readonly forceScreenOrientation: ForceScreenOrientationPlugin;

  protected debug(...args: unknown[]) {
    console.debug(new Date().toLocaleTimeString(), "ClientConnection -", ...args);
  }

  constructor(isHost: boolean) {
    super();
    this.isHost = isHost;
    this.playersManager = new PlayersManager();
    this.gameEvents = new GameEventEmitter();
    this.frameEvents = new FrameEventEmitter();
    this.forceScreenOrientation = new ForceScreenOrientationPlugin();
  }

  public setup(): void {
    if (this.setupDone) {
      console.warn("Client already setup");
      return;
    }
    this.setupDone = true;
    this.addPlugin(this.forceScreenOrientation);
    this.plugins.forEach((plugin) => {
      plugin.setup(this);
    });

    window.requestAnimationFrame(this.step);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected handleMessageFromFrame = (_message: UnknownFrameMessage): void => {
    throw new Error("Not implemented. Override in subclass!");
  };

  setupIframe(iframeElement: HTMLIFrameElement): void {
    // if (this.iframeElement) {
    //   throw new Error("Client iframe already set!");
    // }
    this.iframeElement = iframeElement;
    window.addEventListener("message", this.handleMessageFromFrame);
  }

  postMessageToFrame(message: object, expectFrame = true): void {
    if (!this.iframeElement) {
      expectFrame && console.warn("postMessageToFrame - Client iframe not found - message:", message);
      return;
    }
    if (!this.iframeElement?.contentWindow) {
      expectFrame && console.warn("postMessageToFrame - Client iframe.contentWindow not found - message:", message);
      return;
    }

    this.iframeElement.contentWindow.postMessage(message, "*");
  }

  postEventToFrame<T extends FrameEventName>(eventName: T, data: FrameEventData<T>, expectFrame = true): void {
    this.postMessageToFrame(createFrameEventMessage(eventName, data), expectFrame);
  }

  getIsHost(): boolean {
    return this.isHost;
  }

  addPlugin(plugin: ClientPlugin) {
    if (this.getPluginByName(plugin.getName())) {
      throw new Error(`Plugin with name ${plugin.getName()} already exists`);
    }
    this.plugins.push(plugin);
    this.pluginsByName[plugin.getName()] = plugin;
  }

  getPluginByName<T extends ClientPlugin>(name: string): T | null {
    return this.pluginsByName[name] as T;
  }

  removePlugin(plugin: ClientPlugin) {
    this.removePluginByName(plugin.getName());
  }

  removePluginByName(name: string) {
    const plugin = this.getPluginByName(name);
    if (!plugin) {
      console.warn(`Plugin with name ${name} not found`);
      return;
    }
    plugin.destroy();
    this.plugins = this.plugins.filter((p) => p.getName() !== name);
    delete this.pluginsByName[name];
  }

  /**
   * UPDATE LOOP
   */

  private updateTime: number | undefined = undefined;
  private updateRateMs = 1000;

  private step = (time: number) => {
    if (this.updateTime === undefined) {
      this.updateTime = time;
    }
    const deltaTime = time - this.updateTime;

    if (deltaTime >= this.updateRateMs) {
      this.update(deltaTime, time);
      this.updateTime = time;
    }

    window.requestAnimationFrame(this.step);
  };

  protected update(deltaTime: number, time: number) {
    this.plugins.forEach((plugin) => {
      plugin.update(deltaTime, time);
    });
  }
}

export type BaseClientType = BaseClient<BaseClientEvents>;

export default BaseClient;
