export async function wait(duration: number) {
  return new Promise((resolve) => setTimeout(resolve, duration));
}

export type WaitUntilTrueOptions = {
  interval?: number;
  maxAttempts?: number;
};

export async function waitUntilTrue(predicate: () => boolean, options?: WaitUntilTrueOptions) {
  const { interval, maxAttempts } = {
    interval: 0,
    maxAttempts: Infinity,
    ...options,
  };

  let attempts = 0;
  let result = false;
  while (!result && attempts < maxAttempts) {
    result = predicate();
    await wait(interval);
    attempts++;
  }

  return result;
}
