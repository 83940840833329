import { HOST_PLAYER_ID } from "@shared/src/gc/constants";
import { PlaylistSharedGameState } from "@shared/src/gc/types";
import { assert } from "../../../logger/assert";
import { shuffle } from "../../../utils/array";
import GameEntry from "../../GameEntry";
import Player from "../../Player";
import HostClient, { HostClientType } from "../../host/HostClient";
import HostPlugin, { HostPluginEvents } from "../hud/HostPlugin";
import PlaylistBlueprint from "./PlaylistBlueprint";
import { PlaylistData, PlaylistGameEntry } from "./types";

type StateInterfaceBase = {
  callbacks?: {
    onExit?: () => void;
    onUpdate?: (deltaTime?: number, time?: number) => void;
  };
  actions?: unknown;
};

export const PLAYLIST_STATES = {
  NONE: "none",
  SETUP_GAME: "setup_game",
  PRE_PLAY: "pre_play",
  PLAY: "play",
  POST_PLAY: "post_play",
  GAME_END: "game_end",
  NEXT_GAME_PREVIEW: "next_game_preview",
  PLAYLIST_END: "playlist_end",
} as const;

export type PlaylistState = (typeof PLAYLIST_STATES)[keyof typeof PLAYLIST_STATES];

export type PlaylistSharedClientState = {
  id: number;
  state: PlaylistState;
  isPaused: boolean;
  playerIds: number[];
  playlistGameEntry: PlaylistGameEntry | null;
  nextPlaylistGameEntry: PlaylistGameEntry | null;
  totalRounds: number;
  roundsPerGame: number;
  nonWarmupRoundsPlayed: number;
};

type StateInterface = {
  [PLAYLIST_STATES.NONE]: undefined;
  [PLAYLIST_STATES.SETUP_GAME]: StateInterfaceBase;
  [PLAYLIST_STATES.PRE_PLAY]: StateInterfaceBase & {
    actions: {
      play: () => void;
    };
  };
  [PLAYLIST_STATES.PLAY]: StateInterfaceBase & {
    actions: {
      skipRound: () => void;
      pause: (pause: boolean) => void;
    };
  };
  [PLAYLIST_STATES.POST_PLAY]: StateInterfaceBase & {
    actions: {
      next: () => void;
    };
  };
  [PLAYLIST_STATES.GAME_END]: StateInterfaceBase & {
    actions: {
      next: () => void;
    };
  };
  [PLAYLIST_STATES.NEXT_GAME_PREVIEW]: StateInterfaceBase & {
    actions: {
      next: () => void;
    };
  };
  [PLAYLIST_STATES.PLAYLIST_END]: StateInterfaceBase & {
    actions: {
      next: () => void;
    };
  };
};

export type StateInterfaceForState<T extends PlaylistState> = StateInterface[T];

export type PlaylistPluginEvents = HostPluginEvents & {
  state_change: (data: { prevState: PlaylistState; newState: PlaylistState }) => void;
  pause: (pause: boolean) => void;
  exit: () => void;
};

class PlaylistPlugin extends HostPlugin<PlaylistPluginEvents> {
  static playlistIdCounter = 1;

  static createPlaylistDataFromBlueprint(playlistBlueprint: PlaylistBlueprint) {
    const gameEntryIds = playlistBlueprint.isRandomizedGameOrder()
      ? shuffle(playlistBlueprint.getGameEntryIds())
      : playlistBlueprint.getGameEntryIds();

    const roundsPerGame = playlistBlueprint.getNumberOfRounds();
    let totalNonWarmupRounds = 0;

    const gameEntries = gameEntryIds.reduce((acc, gameEntryId) => {
      if (playlistBlueprint.isWarmupEnabled()) {
        acc.push({
          isWarmup: true,
          roundNumber: 0,
          totalRounds: roundsPerGame,
          gameEntryId: gameEntryId,
        });
      }

      for (let i = 0; i < roundsPerGame; i++) {
        totalNonWarmupRounds++;
        acc.push({
          isWarmup: false,
          roundNumber: i + 1,
          totalRounds: roundsPerGame,
          gameEntryId: gameEntryId,
        });
      }

      return acc;
    }, new Array<PlaylistGameEntry>());

    const playlistData: PlaylistData = {
      roundsPerGame: roundsPerGame,
      totalRounds: totalNonWarmupRounds,
      minPlayers: playlistBlueprint.getMinPlayers(),
      maxPlayers: playlistBlueprint.getMaxPlayers(),
      gameEntries,
    };

    return playlistData;
  }

  private playlistId: number = PlaylistPlugin.playlistIdCounter++;
  private currentStateId: PlaylistState = PLAYLIST_STATES.NONE;
  private currentStateInterface: StateInterfaceBase | null | undefined = null;
  private playlist: PlaylistData | null = null;
  private playlistIndex = -1;
  private playlistPlayers: Player[] = [];
  private isPaused = false;

  private STATE_MACHINE: Record<
    string,
    {
      validNextStates: string[];
      onEnter?: (hostClient: HostClientType) => StateInterfaceBase | void;
    }
  > = {
    [PLAYLIST_STATES.NONE]: {
      validNextStates: [PLAYLIST_STATES.SETUP_GAME],
    },
    [PLAYLIST_STATES.SETUP_GAME]: {
      validNextStates: [PLAYLIST_STATES.PRE_PLAY, PLAYLIST_STATES.PLAYLIST_END],
      onEnter: (hostClient) => {
        const gameEntry = this.getNextGameEntry();
        if (!gameEntry) {
          throw new Error("No next game available in playlist");
        }

        this.playlistIndex++;

        const setupCompleteEventId = hostClient.gameManager.on("setup_complete", () => {
          if (!this.checkValidPlayerCount()) {
            this.setState(PLAYLIST_STATES.PLAYLIST_END);
            return;
          }
          this.setState(PLAYLIST_STATES.PRE_PLAY);
        });

        if (hostClient.gameManager.getGameEntry()) {
          hostClient.gameManager.unloadGame();
        }

        const onPlayerLeftEventId = hostClient.on("party_player_left", this.handlePlayerLeftDuringPlaylist);

        const setupGameActions = hostClient.gameManager.setupGame(
          gameEntry,
          hostClient.party.getPlayers().map((p) => p.getPlayerId()),
        );

        return {
          callbacks: {
            onUpdate: () => {
              if (this.isPaused) {
                return;
              }

              if (setupGameActions.getWaitingClientIds().length > 0) {
                return;
              }

              // players may join or leave while the setup is in progress,
              // so it's important to get the list of players here
              this.playlistPlayers = setupGameActions
                .getDoneClientIds()
                .map((clientId) => {
                  if (clientId === HOST_PLAYER_ID) {
                    return null;
                  }

                  return hostClient.party.getPlayerByPlayerId(clientId);
                })
                .filter(Boolean);

              setupGameActions.completeGameSetup();
            },
            onExit: () => {
              hostClient.gameManager.off(setupCompleteEventId);
              hostClient.off(onPlayerLeftEventId);
            },
          },
        };
      },
    },
    [PLAYLIST_STATES.PRE_PLAY]: {
      validNextStates: [PLAYLIST_STATES.PLAY, PLAYLIST_STATES.PLAYLIST_END],
      onEnter: (hostClient) => {
        const onPlayerJoinedEventId = hostClient.on("party_player_joined", this.handlePlayerJoinedDuringPlay);
        const onPlayerLeftEventId = hostClient.on("party_player_left", this.handlePlayerLeftDuringPlaylist);

        return {
          actions: {
            play: () => {
              if (!this.checkValidPlayerCount()) {
                this.setState(PLAYLIST_STATES.PLAYLIST_END);
                return;
              }
              this.setState(PLAYLIST_STATES.PLAY);
            },
          },
          callbacks: {
            onExit: () => {
              hostClient.off(onPlayerJoinedEventId);
              hostClient.off(onPlayerLeftEventId);
            },
          },
        };
      },
    },
    [PLAYLIST_STATES.PLAY]: {
      validNextStates: [PLAYLIST_STATES.POST_PLAY, PLAYLIST_STATES.PLAYLIST_END],
      onEnter: (hostClient) => {
        hostClient.postEventToFrame("playlist_play", this.getSharedGameState());

        const handleEndGame = () => {
          this.setState(PLAYLIST_STATES.POST_PLAY);
        };

        const handleSkipRound = () => {
          const gameEntry = hostClient.gameManager.getGameEntry();
          assert(gameEntry, "expected game");

          const playerIdsByPlacement: number[] = [];

          hostClient.party.partyStats.addGameResult({
            playlistId: this.playlistId,
            gameId: gameEntry.getGameId(),
            playerIdsByPlacement,
          });

          handleEndGame();
        };

        const onPlayerJoinedEventId = hostClient.on("party_player_joined", this.handlePlayerJoinedDuringPlay);
        const onPlayerLeftEventId = hostClient.on("party_player_left", this.handlePlayerLeftDuringPlaylist);

        const onGameEndEventId = hostClient.frameEvents.on("playlist_host_game_end", ({ playerIdsByPlacement }) => {
          const gameEntry = hostClient.gameManager.getGameEntry();
          assert(gameEntry, "expected game");

          // do not record scores from warmup rounds
          if (!gameEntry.getIsWarmup()) {
            assert(playerIdsByPlacement, "playerIdsByPlacement is required");
            assert(
              playerIdsByPlacement.length === this.playlistPlayers.length,
              "playerIdsByPlacement must contain all players",
            );

            hostClient.party.partyStats.addGameResult({
              playlistId: this.playlistId,
              gameId: gameEntry.getGameId(),
              playerIdsByPlacement,
            });
          }

          handleEndGame();
        });

        return {
          callbacks: {
            onExit: () => {
              hostClient.frameEvents.off(onGameEndEventId);
              hostClient.off(onPlayerJoinedEventId);
              hostClient.off(onPlayerLeftEventId);
            },
          },
          actions: {
            skipRound: handleSkipRound,
          },
        };
      },
    },
    [PLAYLIST_STATES.POST_PLAY]: {
      validNextStates: [PLAYLIST_STATES.GAME_END, PLAYLIST_STATES.PLAYLIST_END],
      onEnter: (hostClient) => {
        const onPlayerLeftEventId = hostClient.on("party_player_left", this.handlePlayerLeftDuringPlaylist);

        return {
          actions: {
            next: () => {
              const nextGame = this.getNextGameEntry();

              if (!nextGame) {
                this.setState(PLAYLIST_STATES.PLAYLIST_END);
              } else {
                this.setState(PLAYLIST_STATES.GAME_END);
              }
            },
          },
          callbacks: {
            onExit: () => {
              hostClient.off(onPlayerLeftEventId);
            },
          },
        };
      },
    },
    [PLAYLIST_STATES.GAME_END]: {
      validNextStates: [PLAYLIST_STATES.NEXT_GAME_PREVIEW, PLAYLIST_STATES.SETUP_GAME, PLAYLIST_STATES.PLAYLIST_END],
      onEnter: (hostClient) => {
        const onPlayerLeftEventId = hostClient.on("party_player_left", this.handlePlayerLeftDuringPlaylist);

        return {
          actions: {
            next: () => {
              const currentPlaylistGameEntry = this.playlist?.gameEntries[this.playlistIndex];
              const wasLastRound = currentPlaylistGameEntry?.roundNumber === currentPlaylistGameEntry?.totalRounds;

              if (wasLastRound) {
                this.setState(PLAYLIST_STATES.NEXT_GAME_PREVIEW);
              } else {
                this.nextGame();
              }
            },
          },
          callbacks: {
            onExit: () => {
              hostClient.off(onPlayerLeftEventId);
            },
          },
        };
      },
    },
    [PLAYLIST_STATES.NEXT_GAME_PREVIEW]: {
      validNextStates: [PLAYLIST_STATES.SETUP_GAME, PLAYLIST_STATES.PLAYLIST_END],
      onEnter: (hostClient) => {
        const onPlayerLeftEventId = hostClient.on("party_player_left", this.handlePlayerLeftDuringPlaylist);

        return {
          actions: {
            next: () => {
              this.nextGame();
            },
          },
          callbacks: {
            onExit: () => {
              hostClient.off(onPlayerLeftEventId);
            },
          },
        };
      },
    },
    [PLAYLIST_STATES.PLAYLIST_END]: {
      validNextStates: [PLAYLIST_STATES.NONE],
      onEnter: () => {
        return {
          actions: {
            next: () => {
              this.exit();
            },
          },
        };
      },
    },
  };

  private handlePlayerJoinedDuringPlay = ({ playerId }: { playerId: number }) => {
    const isPlaying = this.playlistPlayers.some((player) => player.getPlayerId() === playerId);
    if (!isPlaying) return;

    const currentGameEntry = this.playlist?.gameEntries[this.playlistIndex];
    if (!currentGameEntry) return;

    HostClient.gameManager.sendSetupGameEventToPlayer(playerId);
  };

  private handlePlayerLeftDuringPlaylist = ({ playerId }: { playerId: number }) => {
    // set pause in case player leaves, so that he can rejoin/reconnect if necessary
    if (this.playlistPlayers.some((player) => player.getPlayerId() === playerId)) {
      !this.isPaused && this.pause(true);
    }
  };

  protected debug(...args: unknown[]) {
    console.debug(new Date().toLocaleTimeString(), "PlaylistHostPlugin -", ...args);
  }

  constructor() {
    super("PlaylistHostPlugin");
  }

  private lockStateChange = false;

  private setState = (newStateId: PlaylistState) => {
    if (this.lockStateChange) {
      throw new Error(
        "Cannot change state while state change is locked while current state is executing onEnter. You likely called setState inside current states onEnter (before it returned). Make sure to allow onEnter to return before changing state again. If you need to call another state right away, you should instead do it before you switched to the current state in the first place. Trying to change state from " +
          this.currentStateId +
          " to " +
          newStateId,
      );
    }

    const currentState = this.STATE_MACHINE[this.currentStateId];
    const newState = this.STATE_MACHINE[newStateId];

    this.debug("setState from", this.currentStateId, "to", newStateId);

    if (!currentState.validNextStates.includes(newStateId)) {
      throw new Error(`Invalid state transition from ${this.currentStateId} to ${newStateId}`);
    }

    this.currentStateId = newStateId;

    this.currentStateInterface?.callbacks?.onExit?.();
    this.currentStateInterface = null;

    if (newState.onEnter) {
      // Lock state while onEnter executes. This is to prevent changing state while onEnter has not returned,
      // as it would cause state transition to occur in wrong order.
      this.lockStateChange = true;
      const stateInterface = newState.onEnter(this.getClient());
      this.lockStateChange = false;
      this.currentStateInterface = stateInterface ?? null;
    }

    this.dispatch("state_change", {
      prevState: this.currentStateId,
      newState: newStateId,
    });
  };

  private getNextGameEntry = (): GameEntry | null => {
    const playlistGameEntry = this.playlist?.gameEntries[this.playlistIndex + 1] || null;
    if (!playlistGameEntry) return null;

    const gameEntry = GameEntry.getGameEntryDataById(playlistGameEntry.gameEntryId);
    assert(gameEntry, `expected gameEntry by id "${playlistGameEntry.gameEntryId}"`);

    return new GameEntry({
      ...gameEntry,
      isWarmup: playlistGameEntry.isWarmup,
    });
  };

  private checkValidPlayerCount() {
    return this.playlistPlayers.length >= 2;
  }

  start(playlist: PlaylistData) {
    this.playlist = playlist;
    this.playlistPlayers = this.getClient().party.getPlayers();

    if (!this.checkValidPlayerCount()) {
      throw new Error("Not enough players to start playlist");
    }

    this.setState(PLAYLIST_STATES.SETUP_GAME);
  }

  getPlaylistId() {
    return this.playlistId;
  }

  getState() {
    return this.currentStateId;
  }

  nextGame() {
    this.playlistPlayers = this.getClient().party.getPlayers();

    if (!this.checkValidPlayerCount()) {
      this.setState(PLAYLIST_STATES.PLAYLIST_END);
      return;
    }

    this.setState(PLAYLIST_STATES.SETUP_GAME);
  }

  /**
   * PlaylistSharedGameState is shared with the game plugin counterpart.
   **/
  getSharedGameState = (): PlaylistSharedGameState => {
    return {
      players: this.playlistPlayers.map((p) => p.toGameData()),
      isPaused: this.isPaused,
    };
  };

  /**
   * PlaylistSharedClientState is shared with the client/UI.
   **/
  getSharedClientState = (): PlaylistSharedClientState => {
    assert(this.playlist, "expected playlist");
    return {
      id: this.playlistId,
      state: this.currentStateId,
      isPaused: this.isPaused,
      playerIds: this.playlistPlayers.map((p) => p.getPlayerId()),
      playlistGameEntry: this.playlist.gameEntries[this.playlistIndex] || null,
      nextPlaylistGameEntry: this.playlist.gameEntries[this.playlistIndex + 1] || null,
      totalRounds: this.playlist.totalRounds,
      roundsPerGame: this.playlist.roundsPerGame,
      nonWarmupRoundsPlayed: this.playlist.gameEntries.slice(0, this.playlistIndex + 1).reduce((acc, entry) => {
        return entry.isWarmup ? acc : acc + 1;
      }, 0),
    };
  };

  getStateInterfaceAs<T extends PlaylistState>(stateId: T): StateInterfaceForState<T> {
    assert(this.currentStateId === stateId, `Expected state ${stateId} but current state is ${this.currentStateId}`);
    const stateInterface = this.currentStateInterface as StateInterfaceForState<T>;
    assert(stateInterface, `Expected state interface for state ${stateId}`);
    return stateInterface;
  }

  skipRound() {
    const playStateInterface = this.getStateInterfaceAs(PLAYLIST_STATES.PLAY);

    playStateInterface.actions.skipRound();
  }

  pause(pause: boolean) {
    this.debug("pause", pause);
    this.isPaused = pause;
    this.dispatch("pause", this.isPaused);
  }

  togglePause() {
    this.pause(!this.isPaused);
  }

  end() {
    this.setState(PLAYLIST_STATES.PLAYLIST_END);
  }

  exit() {
    if (this.currentStateId !== PLAYLIST_STATES.PLAYLIST_END) {
      throw new Error(
        `Playlist exit can only be called from PLAYLIST_STATES.PLAYLIST_END, current state: ${this.currentStateId}`,
      );
    }

    this.getClient().gameManager.unloadGame();
    this.dispatch("exit");
  }

  update = (deltaTime: number, time: number) => {
    this.currentStateInterface?.callbacks?.onUpdate?.(deltaTime, time);
  };
}

export default PlaylistPlugin;
