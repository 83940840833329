import React, {useEffect} from "react";
import gameanalytics from "gameanalytics";

const GameAnalyticsScript: React.FC = () => {
  useEffect(() => {
    const key = import.meta.env.VITE_GAME_ANALYTICS_KEY;
    const secret = import.meta.env.VITE_GAME_ANALYTICS_SECRET;

    if (!key || !secret) {
      console.warn("GameAnalytics key or secret is missing");
      return;
    }
    gameanalytics.GameAnalytics.setEnabledInfoLog(true);
    gameanalytics.GameAnalytics.initialize(key, secret);
  }, []);

  return null;
};

export default GameAnalyticsScript;
